import React from "react";
import { Link } from "react-router-dom";
import { PropertyGoo } from "../../components/PropertyGoo";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyTwoCoins } from "../../components/PropertyTwoCoins";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { Iconify11 } from "../../icons/Iconify11";
import { Icons30 } from "../../icons/Icons30";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { Property1Variant7 } from "../../icons/Property1Variant7";
import { X6 } from "../../icons/X6";
import "./style.css";

export const Typing = () => {
  return (
    <div className="typing">
      <div className="div-10">
        <div className="overlap-4">
          <div className="frame-235">
            <div className="frame-236">
              <div className="frame-237">
                <div className="group-77">
                  <div className="frame-238">
                    <div className="text-wrapper-133">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-239">
                  <p className="stake-to-mint-GOO-7">
                    <span className="text-wrapper-134">Stake to mint </span>
                    <span className="text-wrapper-135">$GOO</span>
                  </p>
                  <p className="text-wrapper-136">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-240">
                <div className="frame-241">
                  <div className="text-wrapper-137">Total GOO for Mint</div>
                  <div className="frame-242">
                    <div className="text-wrapper-138">10,000,000 GOO</div>
                  </div>
                </div>
                <div className="frame-243">
                  <div className="text-wrapper-137">Staking Period</div>
                  <div className="frame-242">
                    <div className="text-wrapper-138">3 Days</div>
                  </div>
                </div>
                <div className="frame-244">
                  <div className="text-wrapper-137">Total NUTS Staked</div>
                  <div className="frame-242">
                    <div className="text-wrapper-138">10,000 NUTS</div>
                  </div>
                </div>
                <div className="frame-245">
                  <div className="text-wrapper-137">Total SOL Staked</div>
                  <div className="frame-242">
                    <div className="text-wrapper-138">1,000 SOL</div>
                  </div>
                </div>
              </div>
              <div className="user-profile-3">
                <div className="nuts-3">
                  <PropertyNuts className="icons-16" image="/img/image-1-1.png" imageClassName="icons-17" />
                  <div className="element-5">
                    <div className="text-wrapper-137">Your Total Staked NUTS</div>
                    <div className="frame-242">
                      <div className="text-wrapper-138">100 NUTS</div>
                    </div>
                  </div>
                </div>
                <div className="sol-4">
                  <PropertySol className="icons-16" imageClassName="icons-18" />
                  <div className="element-6">
                    <div className="text-wrapper-137">Your Total Staked SOL</div>
                    <div className="frame-242">
                      <div className="text-wrapper-138">2 SOL</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-246">
              <Tabs
                className="tabs-9"
                iconify29Color="#D4D4D4"
                iconify77Color="#FFBA15"
                img="/img/vector-10-1.svg"
                property1="tabs-tiers"
                vector="/img/vector-10.svg"
              />
              <div className="tiers-7">
                <div className="frame-247">
                  <div className="frame-248">
                    <div className="frame-249">
                      <div className="frame-250">
                        <Icons54 className="icons-19" />
                        <PropertyNuts className="icons-20" image="/img/image-1-1-2x.png" />
                      </div>
                      <p className="div-11">
                        <span className="text-wrapper-139">Stake $NUTS for </span>
                        <span className="text-wrapper-140">Free $GOO Airdrop</span>
                      </p>
                    </div>
                    <div className="text-wrapper-141">0%</div>
                  </div>
                  <PropertyWrapper className="view-3" property1="one" />
                </div>
                <div className="frame-251">
                  <div className="frame-252">
                    <div className="frame-252">
                      <div className="frame-253">
                        <PropertyTarget icon={<Iconify11 className="property-money-instance" />} />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Hard Cap</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">Unlimited</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-253">
                        <Icons53 className="icons-19" />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Amount of GOO</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-253">
                        <PropertyMoney iconify63StyleOverrideClassName="property-money-instance" />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Price</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">Free</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-16"
                    divClassName="wallet-17"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-7">
                <div className="frame-247">
                  <div className="frame-256">
                    <div className="frame-257">
                      <div className="frame-250">
                        <Icons30 className="icons-30-instance" />
                        <PropertySol className="icons-20" />
                      </div>
                      <p className="div-11">
                        <span className="text-wrapper-139">Stake $SOL for </span>
                        <span className="text-wrapper-140">Whitelist Subscription </span>
                      </p>
                    </div>
                    <div className="text-wrapper-144">0%</div>
                  </div>
                  <PropertyWrapper className="view-3" property1="one" />
                </div>
                <div className="frame-251">
                  <div className="frame-252">
                    <div className="frame-252">
                      <div className="frame-253">
                        <PropertyTarget />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Hard Cap</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">6,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-253">
                        <PropertyTwoCoins />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Amount of GOO</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">3,500,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-253">
                        <PropertyMoney />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Price</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">0.0185 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-16"
                    divClassName="wallet-17"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-7">
                <div className="frame-247">
                  <div className="frame-256">
                    <div className="frame-257">
                      <div className="frame-250">
                        <PropertyGoo />
                        <PropertySol className="icons-20" />
                      </div>
                      <p className="stake-NUTS-for-free-6">Stake $SOL for Public Subscription</p>
                    </div>
                    <div className="text-wrapper-144">0%</div>
                  </div>
                  <PropertyWrapper className="view-3" property1="one" />
                </div>
                <div className="frame-251">
                  <div className="frame-252">
                    <div className="frame-252">
                      <div className="frame-253">
                        <PropertyTarget />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Hard Cap</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">3,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-253">
                        <PropertyTwoCoins />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Amount of GOO</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">1,250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-253">
                        <PropertyMoney />
                        <div className="frame-254">
                          <div className="text-wrapper-142">Price</div>
                          <div className="frame-255">
                            <div className="text-wrapper-143">0.0028 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-16"
                    divClassName="wallet-17"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="nav-8">
            <div className="nav-9">
              <div className="group-78">
                <div className="logo-8">
                  <div className="group-79">
                    <div className="frame-258">
                      <div className="group-80">
                        <div className="group-81">
                          <div className="ellipse-36" />
                        </div>
                        <div className="group-82">
                          <div className="ellipse-36" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-83">
                    <img className="group-84" alt="Group" src="/img/group-2.png" />
                    <div className="group-85">
                      <div className="ellipse-37" />
                    </div>
                    <div className="group-86">
                      <div className="ellipse-38" />
                    </div>
                    <div className="group-87">
                      <div className="overlap-group-10">
                        <div className="ellipse-39" />
                        <div className="rectangle-20" />
                      </div>
                    </div>
                    <img className="subtract-8" alt="Subtract" src="/img/subtract.svg" />
                    <div className="frame-259">
                      <div className="rectangle-21" />
                      <div className="ellipse-40" />
                    </div>
                  </div>
                </div>
                <div className="frame-260">
                  <div className="text-wrapper-145">Stake</div>
                  <div className="text-wrapper-146">About</div>
                  <div className="text-wrapper-147">Roadmap</div>
                  <div className="text-wrapper-148">Tokenomic</div>
                </div>
                <div className="frame-261">
                  <div className="frame-262">
                    <div className="twitter-7">
                      <X6 className="x-3" color="white" />
                    </div>
                    <img className="discord-9" alt="Discord" src="/img/discord.svg" />
                  </div>
                  <Wallet className="wallet-18" property1="phantom-wallet-connected" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="rectangle-22" alt="Rectangle" src="/img/rectangle-19-1.svg" />
        <Link className="wallet-connect-4" to="/success-share-on-tw">
          <div className="frame-263">
            <div className="frame-264">
              <div className="frame-265">
                <div className="frame-266">
                  <div className="text-wrapper-149">Stake SOL</div>
                  <Property1Variant7 className="iconify-27" color="#202020" />
                </div>
              </div>
              <p className="please-note-you-can">
                <span className="text-wrapper-150">Please note, you can </span>
                <span className="text-wrapper-151">only stake once</span>
                <span className="text-wrapper-150"> for </span>
                <span className="text-wrapper-151">each tier</span>
              </p>
            </div>
          </div>
          <div className="frame-267">
            <div className="frame-268">
              <div className="frame-269">
                <div className="frame-270">
                  <div className="frame-271">
                    <div className="frame-272">
                      <div className="frame-273">
                        <div className="text-wrapper-152">My SOL:</div>
                        <div className="text-wrapper-153">2000</div>
                      </div>
                      <div className="frame-274">
                        <div className="text-wrapper-154">Minimum:</div>
                        <div className="text-wrapper-155">1 SOL</div>
                      </div>
                    </div>
                    <div className="frame-275">
                      <PropertySol className="icons-22" imageClassName="icons-23" />
                      <div className="frame-276">
                        <input className="input" />
                        <div className="text-wrapper-156">MAX</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-277">
            <div className="frame-278">
              <div className="frame-269">
                <div className="frame-270">
                  <div className="frame-279">
                    <div className="frame-280">
                      <div className="text-wrapper-157">Stake SOL</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
