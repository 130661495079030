/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons54 = ({ className }) => {
  return (
    <svg
      className={`icons-54 ${className}`}
      fill="none"
      height="60"
      viewBox="0 0 60 60"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_273_1330)">
        <circle className="circle" cx="30" cy="30" fill="white" r="29.4" stroke="#FAFAFA" strokeWidth="1.2" />
        <path
          className="path"
          d="M35.6207 45.0797C33.0629 45.0797 30.5025 44.1255 28.4789 42.2076C26.7546 40.5736 25.6338 38.3812 25.3228 36.0343C25.2309 35.3414 24.6898 34.8107 23.9763 34.7141C21.7094 34.4065 19.6545 33.387 18.0335 31.7659C14.0043 27.7358 13.9866 21.1617 17.9941 17.1108C22.0044 13.0572 28.3855 12.9522 32.5211 16.8721C34.2452 18.506 35.3661 20.6984 35.6772 23.0454C35.7691 23.7383 36.3102 24.269 37.0237 24.3656C39.2906 24.6732 41.3456 25.6927 42.9665 27.3138C46.9957 31.3439 47.0134 37.9181 43.0059 41.9689C40.9579 44.0393 38.2907 45.0797 35.6207 45.0797Z"
          fill="#F5BC54"
        />
        <ellipse className="ellipse-76" cx="28.2482" cy="28.7111" fill="white" rx="3.87909" ry="3.8791" />
        <ellipse className="ellipse-76" cx="28.5234" cy="28.9858" fill="black" rx="2.84028" ry="2.84029" />
        <ellipse className="ellipse-76" cx="28.5234" cy="28.9858" fill="black" rx="2.84028" ry="2.84029" />
        <ellipse className="ellipse-76" cx="37.5847" cy="21.3566" fill="white" rx="3.87909" ry="3.8791" />
        <ellipse className="ellipse-76" cx="37.8598" cy="21.6313" fill="black" rx="2.84028" ry="2.84029" />
        <ellipse className="ellipse-76" cx="37.8598" cy="21.6313" fill="black" rx="2.84028" ry="2.84029" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_273_1330">
          <rect className="rect" fill="white" height="60" width="60" />
        </clipPath>
      </defs>
    </svg>
  );
};
