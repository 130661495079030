/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons53 = ({ className }) => {
  return (
    <svg
      className={`icons-53 ${className}`}
      fill="none"
      height="60"
      viewBox="0 0 60 60"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_97_3597)">
        <circle className="circle" cx="30" cy="30" fill="white" r="29.4" stroke="#FAFAFA" strokeWidth="1.2" />
        <path
          className="path"
          d="M30.5583 19.3096C28.1942 19.3056 25.2325 20.0527 22.3106 21.5734C19.3934 23.0941 17.0811 25.0863 15.7238 27.032C14.3658 28.971 13.9986 30.7773 14.6666 32.0656C15.3353 33.3472 17.026 34.0843 19.3961 34.0843C21.7661 34.091 24.7278 33.3406 27.6431 31.8265C30.5649 30.3058 32.8759 28.307 34.2306 26.3679C35.5919 24.4288 35.9505 22.6226 35.2864 21.3343C34.6157 20.0527 32.929 19.3096 30.5583 19.3096ZM36.661 23.9773C36.4286 24.9933 35.9239 26.0359 35.2134 27.0519C33.7126 29.1968 31.2622 31.2886 28.1942 32.8824C25.1263 34.4827 22.0118 35.2863 19.3954 35.2863C18.1603 35.2863 17.0154 35.1003 16.0505 34.7085L16.7538 36.0632C17.4231 37.3515 19.1092 38.0886 21.4806 38.0886C23.8513 38.0886 26.813 37.3449 29.7282 35.8242C32.6501 34.3101 34.961 32.3113 36.3157 30.3656C37.6704 28.4265 38.0423 26.6203 37.3716 25.3386L36.661 23.9773ZM38.5735 25.0929C39.3173 26.866 38.7196 29.0242 37.2985 31.0495C36.0435 32.8492 34.1243 34.5956 31.747 36.0566C32.4841 36.1363 33.2544 36.1761 34.038 36.1761C37.3251 36.1761 40.3001 35.4722 42.3985 34.3765C44.5036 33.2808 45.6591 31.8464 45.6591 30.3988C45.6591 28.9511 44.5036 27.5167 42.3985 26.421C41.3294 25.8632 40.0278 25.405 38.5735 25.0929ZM45.6591 33.3804C44.9817 34.1706 44.052 34.8679 42.9563 35.439C40.6321 36.6476 37.4977 37.3714 34.038 37.3714C32.6302 37.3714 31.2755 37.2519 30.0071 37.0261C28.4665 37.8031 26.9192 38.3808 25.4317 38.7593C25.5114 38.8058 25.5911 38.8456 25.6774 38.8921C27.7759 39.9878 30.7509 40.6917 34.038 40.6917C37.3251 40.6917 40.3001 39.9878 42.3985 38.8921C44.5036 37.7964 45.6591 36.362 45.6591 34.9144V33.3804Z"
          fill="#FFBA15"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_97_3597">
          <rect className="rect" fill="white" height="60" width="60" />
        </clipPath>
      </defs>
    </svg>
  );
};
