/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Icons53 } from "../../icons/Icons53";
import "./style.css";

export const PropertyTwoCoins = () => {
  return <Icons53 className="icons-53" />;
};
