/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Iconify77 = ({ color = "#FFBA15", className }) => {
  return (
    <svg
      className={`iconify-77 ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 34 34"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_273_520)">
        <path
          className="path"
          d="M7.86796 7.32382L15.7361 12.5697C16.1243 12.8284 16.4426 13.1789 16.6628 13.5901C16.883 14.0014 16.9983 14.4606 16.9984 14.9271V29.7525C16.9984 30.009 16.9288 30.2606 16.797 30.4807C16.6652 30.7007 16.4762 30.8808 16.25 31.0018C16.0239 31.1228 15.7691 31.1801 15.513 31.1677C15.2568 31.1553 15.0088 31.0735 14.7955 30.9312L6.92729 25.6867C6.5389 25.4279 6.22045 25.0771 6.00024 24.6656C5.78004 24.2541 5.66489 23.7946 5.66504 23.3279V8.50249C5.665 8.24601 5.73459 7.99434 5.86638 7.77431C5.99818 7.55429 6.18723 7.37417 6.41337 7.25317C6.63951 7.13217 6.89426 7.07484 7.15044 7.08728C7.40661 7.09972 7.6546 7.18148 7.86796 7.32382ZM12.0797 4.41966C12.3059 4.29862 12.5607 4.24128 12.817 4.25376C13.0732 4.26623 13.3213 4.34805 13.5346 4.49049L21.4028 9.73641C21.791 9.99507 22.1093 10.3456 22.3295 10.7568C22.5497 11.168 22.665 11.6273 22.665 12.0937V26.9192C22.6651 27.1756 22.5955 27.4273 22.4637 27.6473C22.3319 27.8674 22.1428 28.0475 21.9167 28.1685C21.6906 28.2895 21.4358 28.3468 21.1796 28.3344C20.9235 28.3219 20.6755 28.2402 20.4621 28.0978L19.1234 27.2053V14.9271C19.1233 14.1108 18.9216 13.3071 18.5363 12.5875C18.1511 11.8678 17.5941 11.2544 16.9148 10.8017L11.3317 7.07874V5.66916C11.3316 5.41268 11.4011 5.16099 11.5329 4.94094C11.6646 4.72088 11.8536 4.54071 12.0797 4.41966ZM19.2013 1.65716L27.0695 6.90307C27.4576 7.16173 27.776 7.51224 27.9962 7.92347C28.2164 8.3347 28.3316 8.79393 28.3317 9.26041V24.0858C28.3317 24.3423 28.2622 24.594 28.1304 24.814C27.9986 25.034 27.8095 25.2141 27.5834 25.3351C27.3572 25.4561 27.1025 25.5135 26.8463 25.501C26.5901 25.4886 26.3421 25.4068 26.1288 25.2645L24.79 24.372V12.0937C24.7899 11.2774 24.5883 10.4738 24.203 9.75415C23.8177 9.0345 23.2607 8.4211 22.5815 7.96841L16.9984 4.24541V2.83582C16.9983 2.57935 17.0679 2.32767 17.1997 2.10764C17.3315 1.88762 17.5206 1.7075 17.7467 1.5865C17.9728 1.46551 18.2276 1.40817 18.4838 1.42061C18.7399 1.43306 18.9879 1.51481 19.2013 1.65716Z"
          fill={color}
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_273_520">
          <rect className="rect" fill="white" height="34" width="34" />
        </clipPath>
      </defs>
    </svg>
  );
};

Iconify77.propTypes = {
  color: PropTypes.string,
};
