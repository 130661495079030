/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Iconify29 = ({ color = "#FFBA15", className }) => {
  return (
    <svg
      className={`iconify-29 ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 34 34"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_273_2033)">
        <path
          className="path"
          d="M28.78 8.96984L26.7499 6.93922C26.4686 6.65797 26.0871 6.5 25.6891 6.5H18.4999V5.75C18.4999 5.33562 18.1643 5 17.7499 5H16.2499C15.8355 5 15.4999 5.33562 15.4999 5.75V6.5H7.62488C7.00379 6.5 6.49988 7.00391 6.49988 7.625V11.375C6.49988 11.9961 7.00379 12.5 7.62488 12.5H25.6891C26.0871 12.5 26.4682 12.342 26.7499 12.0608L28.78 10.0306C29.073 9.73719 29.073 9.26281 28.78 8.96984ZM15.4999 28.25C15.4999 28.6644 15.8355 29 16.2499 29H17.7499C18.1643 29 18.4999 28.6644 18.4999 28.25V23H15.4999V28.25ZM26.3749 15.5H18.4999V14H15.4999V15.5H8.31066C7.9127 15.5 7.5316 15.658 7.24988 15.9392L5.21973 17.9698C4.92676 18.2628 4.92676 18.7377 5.21973 19.0306L7.24988 21.0608C7.53113 21.342 7.9127 21.5 8.31066 21.5H26.3749C26.996 21.5 27.4999 20.9961 27.4999 20.375V16.625C27.4999 16.0039 26.996 15.5 26.3749 15.5Z"
          fill={color}
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_273_2033">
          <rect className="rect" fill="white" height="24" transform="translate(5 5)" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

Iconify29.propTypes = {
  color: PropTypes.string,
};
