/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Iconify11 } from "../../icons/Iconify11";
import { Iconify26 } from "../../icons/Iconify26";
import { Iconify29 } from "../../icons/Iconify29";
import { Iconify63 } from "../../icons/Iconify63";
import { Iconify77 } from "../../icons/Iconify77";
import { Property1ArrowUp2 } from "../../icons/Property1ArrowUp2";
import { Property1Copy } from "../../icons/Property1Copy";
import { Property1StarBoldDoutone } from "../../icons/Property1StarBoldDoutone";
import { Property1TwoCoins } from "../../icons/Property1TwoCoins";
import { Property1Variant7 } from "../../icons/Property1Variant7";
import "./style.css";

export const Iconify = ({ property1 }) => {
  return (
    <>
      {property1 === "two-coins" && <Property1TwoCoins className="instance-node-2" />}

      {property1 === "layer-fill" && <Iconify77 className="instance-node-2" color="#FFBA15" />}

      {property1 === "map-signs" && <Iconify29 className="instance-node-2" color="#FFBA15" />}

      {property1 === "target-dart" && <Iconify11 className="instance-node-2" />}

      {property1 === "money-dollar-circle-fill" && <Iconify63 className="instance-node-2" />}

      {property1 === "arrow-up-2" && <Property1ArrowUp2 className="instance-node-3" />}

      {property1 === "variant-7" && <Property1Variant7 className="instance-node-3" color="#202020" />}

      {property1 === "wallet-fill" && <Iconify26 className="instance-node-2" color="white" />}

      {property1 === "copy" && <Property1Copy className="property-copy" />}

      {property1 === "star-bold-doutone" && <Property1StarBoldDoutone className="instance-node-3" />}
    </>
  );
};

Iconify.propTypes = {
  property1: PropTypes.oneOf([
    "arrow-up-2",
    "target-dart",
    "money-dollar-circle-fill",
    "copy",
    "layer-fill",
    "variant-7",
    "two-coins",
    "star-bold-doutone",
    "wallet-fill",
    "map-signs",
  ]),
};
