/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Iconify29 } from "../../icons/Iconify29";
import { Iconify77 } from "../../icons/Iconify77";
import { Tabs } from "flowbite-react";
import "./style.css";

export const Tabs = ({
  setActiveTab,
  activeTab,
  className,
  iconify29Color = "#FFBA15",
  iconify77Color = "#FFBA15",
  vector = "/img/vector-10.svg",
  img = "/img/vector-10-1.svg",
  divClassName,
  overlapGroupClassName,
  divClassNameOverride,
}) => {

  const handleSetTab = (tab) => {
    console.log("Setting active tab to:", tab); // Log the action of setting a tab
    setActiveTab(tab);
  };

  return (
    // <div className={`tabs ${className}`}>
    //   <div className={`overlap-group-18 ${activeTab} ${overlapGroupClassName}`}>
    //     {activeTab === "tabs-tiers" && (
    //       <>
    //         <img className="vector" alt="Vector" src="/img/vector-11.svg" />
    //         <div className="frame" onClick={ ()=> handleSetTab('tabs-tiers')}>
    //           <div className="div">
    //             <div className="frame-2">
    //               <Iconify77 className="instance-node" color={iconify77Color} />
    //               <div className={`text-wrapper ${divClassName}`}>Tiers</div>
    //             </div>
    //             <img className="vector-2" alt="Vector" src={vector} />
    //           </div>
    //           <div className="frame-3">
    //             <div className="frame-2">
    //               <Iconify29 className="instance-node" color={iconify29Color} />
    //               <div className={`text-wrapper-2 ${divClassNameOverride}`}>Rules</div>
    //             </div>
    //             <img className="vector-2" alt="Vector" src={img} />
    //           </div>
    //         </div>
    //       </>
    //     )}

    //     {activeTab === "tabs-FAQ" && (
    //       <div className="frame" onClick={() => handleSetTab('tabs-FAQ')}>
    //       <div className="overlap-group">
    //         <img className="vector" alt="Vector" src="/img/vector-11.svg" />
    //         <div className="frame-4">
    //           <div className="div">
    //             <div className="frame-2">
    //               <Iconify77 className="instance-node" color="#D4D4D4" />
    //               <div className="text-wrapper-3">Tiers</div>
    //             </div>
    //             <img className="vector-2" alt="Vector" src="/img/vector-10-1.svg" />
    //           </div>
    //           <div className="frame-3">
    //             <div className="frame-2">
    //               <Iconify29 className="instance-node" color="#FFBA15" />
    //               <div className="text-wrapper-4">Rules</div>
    //             </div>
    //             <img className="vector-2" alt="Vector" src="/img/vector-10.svg" />
    //           </div>
    //         </div>
    //       </div>
    //       </div>
    //     )}
    //   </div>
    // </div>

    <div class="tabs">
      <input type="radio" id="radio-1" name="tabs" checked={activeTab === 'tabs-tiers'} onChange={() => setActiveTab('tabs-tiers')} />
      <label class="tab" for="radio-1">
        <Iconify77 class="instance-node" color={activeTab === 'tabs-tiers' ? '#FFBA15' : '#D4D4D4'} />
        Tiers
      </label>
      <input type="radio" id="radio-2" name="tabs" checked={activeTab === 'tabs-FAQ'} onChange={() => setActiveTab('tabs-FAQ')} />
      <label class="tab" for="radio-2">
        <Iconify29 class="instance-node" color={activeTab === 'tabs-FAQ' ? '#FFBA15' : '#D4D4D4'} />
        Rules
      </label>
      <span class="glider"></span>
    </div>
  );
};

Tabs.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconify29Color: PropTypes.string,
  iconify77Color: PropTypes.string,
  vector: PropTypes.string,
  img: PropTypes.string,
  divClassName: PropTypes.string,
  divClassNameOverride: PropTypes.string,
  overlapGroupClassName: PropTypes.string
};
