import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { Footer } from "../../components/Footer";
import { StatusBar } from "../../components/StatusBar";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { Iconify26 } from "../../icons/Iconify26";
import { Iconify30 } from "../../icons/Iconify30";
import { Iconify31 } from "../../icons/Iconify31";
import { X6 } from "../../icons/X6";
import "./style.css";

export const MobileNav = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="mobile-nav"
      style={{
        backgroundColor: screenWidth < 1920 ? "transparent" : screenWidth >= 1920 ? "#ffffff" : undefined,
      }}
    >
      <div
        className="mobile-nav-status"
        style={{
          backgroundColor: screenWidth >= 1920 ? "#ffffff" : undefined,
          backgroundImage: screenWidth < 1920 ? "url(/img/rectangle-20.svg)" : undefined,
          backgroundSize: screenWidth < 1920 ? "100% 100%" : undefined,
          height: screenWidth < 1920 ? "814px" : screenWidth >= 1920 ? "2161px" : undefined,
          width: screenWidth < 1920 ? "375px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        {screenWidth >= 1920 && (
          <>
            <div className="frame-79">
              <div className="frame-80">
                <div className="group-12">
                  <div className="frame-81">
                    <div className="text-wrapper-52">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-82">
                  <p className="stake-to-mint-GOO-2">
                    <span className="text-wrapper-53">Stake to mint </span>
                    <span className="text-wrapper-54">$GOO</span>
                  </p>
                  <p className="text-wrapper-55">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-83">
                <div className="frame-84">
                  <div className="text-wrapper-56">Total GOO for Mint</div>
                  <div className="frame-85">
                    <div className="text-wrapper-57">10,000,000 GOO</div>
                  </div>
                </div>
                <div className="frame-86">
                  <div className="text-wrapper-56">Staking Period</div>
                  <div className="frame-85">
                    <div className="text-wrapper-57">3 Days</div>
                  </div>
                </div>
                <div className="frame-87">
                  <div className="text-wrapper-56">Total NUTS Staked</div>
                  <div className="frame-85">
                    <div className="text-wrapper-57">10,000 NUTS</div>
                  </div>
                </div>
                <div className="frame-88">
                  <div className="text-wrapper-56">Total SOL Staked</div>
                  <div className="frame-85">
                    <div className="text-wrapper-57">1,000 SOL</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-wrapper">
              <Tabs
                className="tabs-5"
                divClassName="tabs-3"
                divClassNameOverride="tabs-4"
                iconify29Color="#FFBA15"
                iconify77Color="#D4D4D4"
                img="/img/vector-10.svg"
                overlapGroupClassName="tabs-2"
                property1="tabs-tiers"
                to="/stake-page-tiers-1"
                vector="/img/vector-10-1.svg"
              />
            </div>
          </>
        )}

        <div
          className="frame-89"
          style={{
            gap: screenWidth >= 1920 ? "40px" : screenWidth < 1920 ? "16px" : undefined,
            left: screenWidth >= 1920 ? "92px" : screenWidth < 1920 ? "16px" : undefined,
            top: screenWidth >= 1920 ? "637px" : screenWidth < 1920 ? "121px" : undefined,
          }}
        >
          <div
            className="frame-90"
            style={{
              gap: screenWidth < 1920 ? "6px" : screenWidth >= 1920 ? "14px" : undefined,
            }}
          >
            {screenWidth < 1920 && (
              <>
                <div className="wallet-7">
                  <div className="frame-91">
                    <div className="text-wrapper-52">Connect Wallet</div>
                  </div>
                  <Iconify26 className="property-1-wallet-fill" color="#FFBA15" />
                </div>
                <div className="wallet-8">
                  <div className="frame-92">
                    <div className="text-wrapper-58">Stake</div>
                  </div>
                </div>
                <div className="wallet-8">
                  <div className="frame-92">
                    <div className="text-wrapper-58">About</div>
                  </div>
                </div>
                <div className="wallet-8">
                  <div className="frame-92">
                    <div className="text-wrapper-58">Roadmap</div>
                  </div>
                </div>
                <div className="wallet-9">
                  <div className="frame-92">
                    <div className="text-wrapper-58">Tokenomic</div>
                  </div>
                </div>
              </>
            )}

            {screenWidth >= 1920 && (
              <>
                <div className="text-wrapper-59">Subscription Rules</div>
                <div className="flexcontainer">
                  <p className="text">
                    <span className="text-wrapper-60">
                      All 3 subscription tiers will start and end at the same time.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="text-wrapper-60">The subscription will last for</span>
                    <span className="text-wrapper-61"> 3 days</span>
                    <span className="text-wrapper-60">
                      .<br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="text-wrapper-60">
                      Minimum amount to stake is 2 NUTS/ 0.5 SOL.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="text-wrapper-60">For </span>
                    <span className="text-wrapper-61">free subscription:</span>
                    <span className="text-wrapper-62">&nbsp;</span>
                    <span className="text-wrapper-60">
                      all your staked tokens (NUTS) will be returned to your wallet at the end of the period.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="text-wrapper-60">For </span>
                    <span className="text-wrapper-61">whitelist and public subscription:</span>
                    <span className="text-wrapper-60">
                      {" "}
                      tokens you staked will be automatically deducted proportionally at the end of the period.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="text-wrapper-60">
                      In the case of oversubscribed for whitelist and public subscription, your staked tokens will be
                      returned to your wallet proportionally.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="text-wrapper-60">
                      The amount of GOO you get will be based on the time you stake and the quantity of token you stake
                      verse the total average staked. Snapshot will be taken every hour, a timestamp and the quantity
                      will be recorded when you stake.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="text-wrapper-60">Once the subscription period start, </span>
                    <span className="text-wrapper-61">you can only stake once</span>
                    <span className="text-wrapper-60">, and you </span>
                    <span className="text-wrapper-61">cannot unstake or redeem during the subscription period</span>
                    <span className="text-wrapper-60">, please make informed decision for your staking amount.</span>
                  </p>
                </div>
              </>
            )}
          </div>
          {screenWidth >= 1920 && (
            <div className="frame-93">
              <div className="text-wrapper-59">FAQs</div>
              <div className="frame-94">
                <div className="frame-95">
                  <div className="frame-96">
                    <p className="text-wrapper-63">Who is Eligible for The NUTS Staking Free Subscription Tier?</p>
                    <Iconify30 className="iconify-30" />
                  </div>
                  <p className="text-wrapper-64">
                    Nuts holder that holds more than 100 NUTS before the snapshot will be eligible to participate in
                    this tier!
                  </p>
                </div>
                <div className="frame-97">
                  <p className="text-wrapper-65">How Do I Get Whitelist?</p>
                  <Iconify31 className="iconify-31" />
                </div>
                <div className="frame-98">
                  <p className="text-wrapper-65">What Smart Contract is GOO Associated With?</p>
                  <Iconify31 className="iconify-31" />
                </div>
                <div className="frame-99">
                  <p className="text-wrapper-65">
                    After Staking Period Ends, How Long Will I Get My Goo &amp; Rest Staking Funds Back?
                  </p>
                  <Iconify31 className="iconify-31" />
                </div>
                <div className="frame-100">
                  <p className="text-wrapper-65">
                    If I Stake SOL in Both the Whitelist and Public Subscription, How Many SOL Will Be Returned to My
                    Wallet After Subscription?
                  </p>
                  <Iconify31 className="iconify-31" />
                </div>
                <div className="frame-101">
                  <p className="text-wrapper-65">How Can I Get Free Airdrop of GOO?</p>
                  <Iconify31 className="iconify-31" />
                </div>
                <div className="frame-102">
                  <p className="text-wrapper-65">When is The Staking Ends?</p>
                  <Iconify31 className="iconify-31" />
                </div>
                <div className="frame-103">
                  <p className="text-wrapper-65">What Does the Hardcap Means?</p>
                  <Iconify31 className="iconify-31" />
                </div>
                <div className="frame-104">
                  <p className="text-wrapper-65">Where Would The Mint Funds Goes?</p>
                  <Iconify31 className="iconify-31" />
                </div>
              </div>
            </div>
          )}

          {screenWidth < 1920 && (
            <>
              <div className="frame-105">
                <div className="x-wrapper">
                  <X6 className="x-6-instance" color="white" />
                </div>
                <img className="discord-4" alt="Discord" src="/img/discord-6-2.svg" />
              </div>
              <p className="element-goober-all-3">
                <span className="text-wrapper-66">©</span>
                <span className="text-wrapper-67">2024 Goober All rights reserved | </span>
                <span className="text-wrapper-68">Terms &amp; Conditons</span>
              </p>
            </>
          )}
        </div>
        {screenWidth >= 1920 && (
          <>
            <Footer className="footer-3" discord="/img/discord-6.svg" elementGooberAllClassName="footer-4" />
            <div className="nav-2">
              <div className="group-13">
                <div className="logo-2">
                  <div className="group-14">
                    <div className="frame-106">
                      <div className="group-15">
                        <div className="group-16">
                          <div className="ellipse-6" />
                        </div>
                        <div className="group-17">
                          <div className="ellipse-6" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-18">
                    <img className="group-19" alt="Group" src="/img/group-2.png" />
                    <div className="group-20">
                      <div className="ellipse-7" />
                    </div>
                    <div className="group-21">
                      <div className="ellipse-8" />
                    </div>
                    <div className="group-22">
                      <div className="overlap-group-4">
                        <div className="ellipse-9" />
                        <div className="rectangle-5" />
                      </div>
                    </div>
                    <img className="subtract-2" alt="Subtract" src="/img/subtract-5.svg" />
                    <div className="frame-107">
                      <div className="rectangle-6" />
                      <div className="ellipse-10" />
                    </div>
                  </div>
                </div>
                <div className="navbar">
                  <div className="text-wrapper-69">Stake</div>
                  <div className="text-wrapper-70">About</div>
                  <div className="text-wrapper-71">Roadmap</div>
                  <div className="text-wrapper-72">Tokenomic</div>
                </div>
                <Link className="frame-108" to="/connect-wallet">
                  <div className="frame-109">
                    <div className="x-6-wrapper">
                      <X6 className="state-default-instance" color="white" />
                    </div>
                    <img className="discord-5" alt="Discord" src="/img/discord-6.svg" />
                  </div>
                  <Wallet
                    className="wallet-10"
                    icon={<Iconify26 className="iconify-70" color="white" />}
                    property1="wallet-connect"
                    text="Connect Wallet"
                  />
                </Link>
              </div>
            </div>
          </>
        )}

        {screenWidth < 1920 && (
          <div className="frame-110">
            <StatusBar
              className="status-bar-instance"
              darkMode={false}
              dynIslSize="default"
              dynamicIsland
              iconMobileSignal="/img/icon-mobile-signal-8.svg"
              statusbarTimeTimeClassName="status-bar-2"
              wifi="/img/wifi-4.svg"
            />
            <div className="nav-3">
              <div className="logo-3">
                <div className="group-23">
                  <div className="frame-111">
                    <div className="group-24">
                      <div className="group-25">
                        <div className="ellipse-11" />
                      </div>
                      <div className="group-26">
                        <div className="ellipse-11" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-27">
                  <img className="group-28" alt="Group" src="/img/group-2-5.png" />
                  <div className="group-29">
                    <div className="ellipse-12" />
                  </div>
                  <div className="group-30">
                    <div className="ellipse-13" />
                  </div>
                  <div className="group-31">
                    <div className="overlap-group-5">
                      <div className="ellipse-14" />
                      <div className="rectangle-7" />
                    </div>
                  </div>
                  <img className="subtract-3" alt="Subtract" src="/img/subtract-9.svg" />
                  <div className="frame-112">
                    <div className="rectangle-8" />
                    <div className="ellipse-15" />
                  </div>
                </div>
              </div>
              <div className="menu" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
