import React, { useState } from 'react';
import { Iconify30 } from "../../icons/Iconify30";  // Assuming this is the expand icon
import { Iconify31 } from "../../icons/Iconify31";  // Assuming this is the collapse icon
import "./style.css";

export const FAQs = () => {
  const [expandedFAQs, setExpandedFAQs] = useState({});

  const toggleFAQ = index => {
    setExpandedFAQs(prevExpandedFAQs => ({
      ...prevExpandedFAQs,
      [index]: !prevExpandedFAQs[index]
    }));
  };

  const faqs = [
    {
      question: "Who is Eligible for The NUTS Staking Free Subscription Tier?",
      answer: "Nuts holder that holds more than 100 NUTS before the snapshot will be eligible to participate in this tier!",
    },
    {
      question: "How Do I Get Whitelist?",
      answer: (
        <div>
          There are various ways to get whitelist for Goober launch:<br/>
          <ul>
            <li>All NUTS holder and LIGMA holder will be eligible for the whitelist mint.</li>
            <li>Goober engagement social activities (X and Discord)</li>
            <li>Partnership whitelist</li>
            <li>Community contributor</li>
          </ul>
        </div>
      ),
    },
    {
      question: "What Smart Contract is GOO Associated With?",
      answer: (
        <span>
          Thanks to <a href="https://twitter.com/sol_idity" target="_blank" rel="noopener noreferrer" class="blue-link">@sol_idity</a>, Goober's smart contract is powered by DeezNuts using the TinySPL Contract.
        </span>
      ),
    },
    {
      question: "After Staking Period Ends, How Long Will I Get My Goo & Rest Staking Funds Back?",
      answer: "Once staking concludes, DeezNuts will facilitate the distribution of GOO and SOL to your wallets via the TinySPL contract.",
    },
    {
      question: "If I Stake SOL in Both the Whitelist and Public Subscription, How Many SOL Will Be Returned to My Wallet After Subscription?",
      answer: "After you've staked your SOL, we'll calculate your quota based on the total amount of SOL staked. If the hardcap isn't met, all your staked SOL will automatically be converted to GOO. If you have any more questions, please refer to our tokenomics for further information.",
    },
    {
      question: "How Can I Get Free Airdrop of GOO?",
      answer: (
      <div>
          1. If you hold over 100 NUTS before the snapshot and stake during the subscription period, you will receive Free GOO via airdrop. <br/>
          <br/>
          2. All tier users who stake on the first day of the subscription period will be allocated GOO by airdrop once the subscription process is complete.
      </div>)
    },
    {
      question: "When is The Staking Ends?",
      answer: "TBD",
    },
    {
      question: "What Does the Hardcap Means?",
      answer: (
        <div>
          The hardcap represents the maximum quota for the subscription. At the end of the subscription period, if the total SOL staked is less than the hardcap, the amount you committed will be your total quota. <br/>
          <br/>
          However, if the total SOL staked exceeds the hardcap, your quota will be calculated based on the timing of your stake and the proportion of SOL you contributed relative to the total staked SOL.
        </div>
      ),
    },
    {
      question: "Where Would The Mint Funds Goes?",
      answer: "Part of the funds raised from the mint will be allocated to liquidity pools once the subscriptions is complete, while the remainder will be used for future development and community incentives.",
    },
    // Additional FAQs here
  ];

  return (
    <div className='faq-section'>
      <h2 className="faq-title">FAQs</h2>
    <div className="faq-container" style={{ maxHeight: '700px', overflowY: 'auto', overflowX: 'hidden' }}>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
            {expandedFAQs[index] ? <Iconify30 /> : <Iconify31 />}
          </div>
          {expandedFAQs[index] && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
        </div>
  );
};