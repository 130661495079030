/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const StateHover = ({ className }) => {
  return (
    <svg
      className={`state-hover ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 51 48"
      width="51"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" filter="url(#filter0_ii_77_886)">
        <path
          className="path"
          clipRule="evenodd"
          d="M17.2293 24.5361L-3 -1H13.023L25.5104 14.7831L38.8512 -0.928937H47.6758L29.7771 20.1759L51 47H35.0248L21.5035 29.9316L7.0682 46.9527H-1.80425L17.2293 24.5361ZM37.3533 42.2686L6.82478 3.73139H10.6927L41.1828 42.2686H37.3533Z"
          fill="#1C1C1C"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="88"
          id="filter0_ii_77_886"
          width="54"
          x="-3"
          y="-21"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feBlend className="fe-blend" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="-20" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="10" />
          <feComposite className="fe-composite" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend className="fe-blend" in2="shape" mode="plus-lighter" result="effect1_innerShadow_77_886" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="20" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="10" />
          <feComposite className="fe-composite" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend
            className="fe-blend"
            in2="effect1_innerShadow_77_886"
            mode="overlay"
            result="effect2_innerShadow_77_886"
          />
        </filter>
      </defs>
    </svg>
  );
};
