/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PropertyNuts } from "../PropertyNuts";
import { PropertySol } from "../PropertySol";
import "./style.css";

export const StakedInfo = ({ className, text = "10,000,000 GOO", text1 = "3 SOL" }) => {
  return (
    <div className={`staked-info ${className}`}>
      <div className="frame-47">
        <PropertyNuts className="icons-3" image="/img/image-1-2.png" imageClassName="icons-4" />
        <div className="frame-48">
          <div className="text-wrapper-32">Your Total Staked NUTS</div>
          <div className="frame-49">
            <div className="text-wrapper-33">{text}</div>
          </div>
        </div>
      </div>
      <div className="frame-50">
        <PropertySol className="icons-3" imageClassName="property-1-SOL" />
        <div className="frame-48">
          <div className="text-wrapper-32">Your Total Staked SOL</div>
          <div className="frame-49">
            <div className="text-wrapper-33">{text1}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

StakedInfo.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};
