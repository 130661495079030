import React from "react";
import { Footer } from "../../components/Footer";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { Tabs } from "../../components/Tabs";
import { Tiers } from "../../components/Tiers";
import { Wallet } from "../../components/Wallet";
import { Iconify11 } from "../../icons/Iconify11";
import { Iconify26 } from "../../icons/Iconify26";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { X6 } from "../../icons/X6";
import CountdownTimer from '../../components/CountdownTimer';
import "./style.css";

export const StakePageTiersScreen = () => {
  return (
    <div className="stake-page-tiers-screen">
      <div className="stake-page-tiers-2">
        <div className="frame-415">
          <div className="frame-416">
            <div className="frame-417">
              <div className="group-133">
                <div className="frame-418">
                  <div className="text-wrapper-225">Contract Power by TinySPL</div>
                </div>
              </div>
              <div className="frame-419">
                <p className="stake-to-mint-GOO-12">
                  <span className="text-wrapper-226">Stake to mint </span>
                  <span className="text-wrapper-227">$GOO</span>
                </p>
                <p className="text-wrapper-228">Stake today for earlier supporter airdrop🎁</p>
              </div>
            </div>
            <div className="frame-420">
              <div className="frame-421">
                <div className="text-wrapper-229">Total GOO for Mint</div>
                <div className="frame-422">
                  <div className="text-wrapper-230">10,000,000 GOO</div>
                </div>
              </div>
              <div className="frame-423">
                <div className="text-wrapper-229">Staking Period</div>
                <div className="frame-422">
                  <div>
                    <CountdownTimer targetDate="2024-04-24T00:00:00" />
                  </div>
                </div>
              </div>
              <div className="frame-424">
                <div className="text-wrapper-229">Total NUTS Staked</div>
                <div className="frame-422">
                  <div className="text-wrapper-230">10,000 NUTS</div>
                </div>
              </div>
              <div className="frame-425">
                <div className="text-wrapper-229">Total SOL Staked</div>
                <div className="frame-422">
                  <div className="text-wrapper-230">1,000 SOL</div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-426">
            <Tabs
              className="tabs-13"
              iconify29Color="#D4D4D4"
              iconify77Color="#FFBA15"
              img="/img/vector-10-1.svg"
              property1="tabs-tiers"
              vector="/img/vector-10.svg"
            />
            <Tiers
              className="design-component-instance-node-3"
              divClassName="tiers-11"
              icon={<Icons54 className="icons-48" />}
              icon1={<Icons53 className="icons-48" />}
              override={<PropertyNuts className="icons-49" image="/img/image-1-1-2x.png" />}
              propertyTargetIcon={<Iconify11 className="iconify-42" />}
              text="Unlimited"
              text1="Free"
              text2="250,000 GOO"
              text3="Stake $NUTS for "
              text4="Free $GOO Airdrop"
              to="/connect-wallet"
              walletIcon={<Iconify26 className="iconify-instance" color="#FFBA15" />}
            />
            <Tiers
              className="design-component-instance-node-3"
              icon={<Icons54 className="icons-48" />}
              icon1={<Icons53 className="icons-48" />}
              override={<PropertySol className="icons-49" />}
              propertyTargetIcon={<Iconify11 className="iconify-42" />}
              text="6,500 SOL"
              text1="0.0185 SOL"
              text2="3,500,000 GOO"
              text3="Stake $NUTS for "
              text4="Free $GOO Airdrop"
              walletIcon={<Iconify26 className="iconify-instance" color="#FFBA15" />}
            />
            <Tiers
              className="design-component-instance-node-3"
              icon={<Icons54 className="icons-48" />}
              icon1={<Icons53 className="icons-48" />}
              override={<PropertySol className="icons-49" />}
              propertyTargetIcon={<Iconify11 className="iconify-42" />}
              text="3,500 SOL"
              text1="0.0028 SOL"
              text2="1,250,000 GOO"
              text3="Stake $NUTS for "
              text4="Free $GOO Airdrop"
              walletIcon={<Iconify26 className="iconify-instance" color="#FFBA15" />}
            />
          </div>
        </div>
        <Footer className="footer-13" discord="/img/discord-6.svg" elementGooberAllClassName="footer-14" />
        <div className="nav-17">
          <div className="group-134">
            <div className="logo-13">
              <div className="group-135">
                <div className="frame-427">
                  <div className="group-136">
                    <div className="group-137">
                      <div className="ellipse-61" />
                    </div>
                    <div className="group-138">
                      <div className="ellipse-61" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-139">
                <img className="group-140" alt="Group" src="/img/group-2.png" />
                <div className="group-141">
                  <div className="ellipse-62" />
                </div>
                <div className="group-142">
                  <div className="ellipse-63" />
                </div>
                <div className="group-143">
                  <div className="overlap-group-15">
                    <div className="ellipse-64" />
                    <div className="rectangle-34" />
                  </div>
                </div>
                <img className="subtract-13" alt="Subtract" src="/img/subtract-5.svg" />
                <div className="frame-428">
                  <div className="rectangle-35" />
                  <div className="ellipse-65" />
                </div>
              </div>
            </div>
            <div className="navbar-4">
              <div className="text-wrapper-233">Stake</div>
              <div className="text-wrapper-234">About</div>
              <div className="text-wrapper-235">Roadmap</div>
              <div className="text-wrapper-236">Tokenomic</div>
            </div>
            <div className="frame-429">
              <div className="frame-430">
                <div className="twitter-11">
                  <X6 className="x-7" color="white" />
                </div>
                <img className="discord-13" alt="Discord" src="/img/discord.svg" />
              </div>
              <Wallet
                className="design-component-instance-node-3"
                icon={<Iconify26 className="iconify-instance" color="white" />}
                property1="wallet-connect"
                text="Connect Wallet"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
