/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Iconify101 = ({ className }) => {
  return (
    <svg
      className={`iconify-101 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_102_12495)">
        <path
          className="path"
          d="M13.5436 4.2207L12.5883 3.26511C12.4559 3.13276 12.2764 3.05842 12.0891 3.05842H8.70591V2.70548C8.70591 2.51048 8.54797 2.35254 8.35297 2.35254H7.64709C7.45209 2.35254 7.29415 2.51048 7.29415 2.70548V3.05842H3.58827C3.29599 3.05842 3.05885 3.29555 3.05885 3.58783V5.35254C3.05885 5.64482 3.29599 5.88195 3.58827 5.88195H12.0891C12.2764 5.88195 12.4557 5.80761 12.5883 5.67526L13.5436 4.71989C13.6815 4.5818 13.6815 4.35857 13.5436 4.2207ZM7.29415 13.2937C7.29415 13.4887 7.45209 13.6467 7.64709 13.6467H8.35297C8.54797 13.6467 8.70591 13.4887 8.70591 13.2937V10.8231H7.29415V13.2937ZM12.4118 7.29372H8.70591V6.58783H7.29415V7.29372H3.91099C3.72371 7.29372 3.54437 7.36805 3.4118 7.50041L2.45643 8.45599C2.31856 8.59386 2.31856 8.81732 2.45643 8.95519L3.4118 9.91055C3.54415 10.0429 3.72371 10.1172 3.91099 10.1172H12.4118C12.7041 10.1172 12.9412 9.88011 12.9412 9.58783V7.82313C12.9412 7.53085 12.7041 7.29372 12.4118 7.29372Z"
          fill="#D4D4D4"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_102_12495">
          <rect className="rect" fill="white" height="11.2941" transform="translate(2.35303 2.35254)" width="11.2941" />
        </clipPath>
      </defs>
    </svg>
  );
};
