import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { Iconify1 } from "../../icons/Iconify1";
import { Iconify11 } from "../../icons/Iconify11";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { X6 } from "../../icons/X6";
import "./style.css";

export const StakeScreen = () => {
  return (
    <div className="stake-screen">
      <div className="stake-2">
        <div className="overlap-9">
          <div className="frame-431">
            <div className="frame-432">
              <div className="frame-433">
                <div className="group-144">
                  <div className="frame-434">
                    <div className="text-wrapper-237">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-435">
                  <p className="stake-to-mint-GOO-13">
                    <span className="text-wrapper-238">Stake to mint </span>
                    <span className="text-wrapper-239">$GOO</span>
                  </p>
                  <p className="text-wrapper-240">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-436">
                <div className="frame-437">
                  <div className="text-wrapper-241">Total GOO for Mint</div>
                  <div className="frame-438">
                    <div className="text-wrapper-242">10,000,000 GOO</div>
                  </div>
                </div>
                <div className="frame-439">
                  <div className="text-wrapper-241">Staking Period</div>
                  <div className="frame-438">
                    <div className="text-wrapper-242">3 Days</div>
                  </div>
                </div>
                <div className="frame-440">
                  <div className="text-wrapper-241">Total NUTS Staked</div>
                  <div className="frame-438">
                    <div className="text-wrapper-242">10,000 NUTS</div>
                  </div>
                </div>
                <div className="frame-441">
                  <div className="text-wrapper-241">Total SOL Staked</div>
                  <div className="frame-438">
                    <div className="text-wrapper-242">1,000 SOL</div>
                  </div>
                </div>
              </div>
              <div className="user-profile-7">
                <div className="nuts-7">
                  <PropertyNuts className="icons-50" image="/img/image-1-1.png" imageClassName="icons-51" />
                  <div className="element-14">
                    <div className="text-wrapper-241">Your Total Staked NUTS</div>
                    <div className="frame-438">
                      <div className="text-wrapper-242">0 NUTS</div>
                    </div>
                  </div>
                </div>
                <div className="sol-8">
                  <PropertySol className="icons-50" imageClassName="icons-52" />
                  <div className="element-15">
                    <div className="text-wrapper-241">Your Total Staked SOL</div>
                    <div className="frame-438">
                      <div className="text-wrapper-242">0 SOL</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link
              className="frame-442"
              to="/u24050u38142u25509u38065u21253u65292u20294u26410stakeu44-u24403u38065u21253u20165u31526u21512u26576u20010tiers"
            >
              <Tabs
                className="design-component-instance-node-4"
                iconify29Color="#D4D4D4"
                iconify77Color="#FFBA15"
                img="/img/vector-10-1.svg"
                property1="tabs-tiers"
                vector="/img/vector-10.svg"
              />
              <div className="tiers-12">
                <div className="frame-443">
                  <div className="frame-444">
                    <div className="frame-445">
                      <div className="frame-446">
                        <Icons54 className="icons-55" />
                        <PropertyNuts className="icons-56" image="/img/image-1-1-2x.png" />
                      </div>
                      <p className="div-16">
                        <span className="text-wrapper-243">Stake $NUTS for </span>
                        <span className="text-wrapper-244">Free $GOO Airdrop</span>
                      </p>
                    </div>
                    <div className="text-wrapper-245">0%</div>
                  </div>
                  <PropertyWrapper className="view-7" property1="one" />
                </div>
                <div className="frame-447">
                  <div className="frame-448">
                    <div className="frame-448">
                      <div className="frame-449">
                        <PropertyTarget icon={<Iconify11 className="property-1-money-bill-wave-alt-instance" />} />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Hard Cap</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">Unlimited</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-449">
                        <Icons53 className="icons-55" />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Amount of GOO</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-449">
                        <PropertyMoney iconify63StyleOverrideClassName="property-1-money-bill-wave-alt-instance" />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Price</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">Free</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-31"
                    divClassName="wallet-32"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-12">
                <div className="frame-443">
                  <div className="frame-452">
                    <div className="frame-453">
                      <div className="frame-446">
                        <Icons54 className="icons-55" />
                        <PropertySol className="icons-56" />
                      </div>
                      <p className="div-16">
                        <span className="text-wrapper-243">Stake $SOL for </span>
                        <span className="text-wrapper-244">Whitelist Subscription </span>
                      </p>
                    </div>
                    <div className="text-wrapper-248">0%</div>
                  </div>
                  <PropertyWrapper className="view-7" property1="one" />
                </div>
                <div className="frame-447">
                  <div className="frame-448">
                    <div className="frame-448">
                      <div className="frame-449">
                        <PropertyTarget icon={<Iconify11 className="property-1-money-bill-wave-alt-instance" />} />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Hard Cap</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">6,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-449">
                        <Icons53 className="icons-55" />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Amount of GOO</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">3,500,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-449">
                        <PropertyMoney iconify63StyleOverrideClassName="property-1-money-bill-wave-alt-instance" />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Price</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">0.0185 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-31"
                    divClassName="wallet-32"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-12">
                <div className="frame-443">
                  <div className="frame-452">
                    <div className="frame-453">
                      <div className="frame-446">
                        <Icons54 className="icons-55" />
                        <PropertySol className="icons-56" />
                      </div>
                      <p className="stake-NUTS-for-free-11">Stake $SOL for Public Subscription</p>
                    </div>
                    <div className="text-wrapper-248">0%</div>
                  </div>
                  <PropertyWrapper className="view-7" property1="one" />
                </div>
                <div className="frame-447">
                  <div className="frame-448">
                    <div className="frame-448">
                      <div className="frame-449">
                        <PropertyTarget icon={<Iconify11 className="property-1-money-bill-wave-alt-instance" />} />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Hard Cap</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">3,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-449">
                        <Icons53 className="icons-55" />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Amount of GOO</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">1,250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-449">
                        <PropertyMoney iconify63StyleOverrideClassName="property-1-money-bill-wave-alt-instance" />
                        <div className="frame-450">
                          <div className="text-wrapper-246">Price</div>
                          <div className="frame-451">
                            <div className="text-wrapper-247">0.0028 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-31"
                    divClassName="wallet-32"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
            </Link>
          </div>
          <Footer className="footer-15" discord="/img/discord.svg" elementGooberAllClassName="footer-16" />
          <div className="nav-18">
            <div className="nav-19">
              <div className="group-145">
                <div className="logo-14">
                  <div className="group-146">
                    <div className="frame-454">
                      <div className="group-147">
                        <div className="group-148">
                          <div className="ellipse-66" />
                        </div>
                        <div className="group-149">
                          <div className="ellipse-66" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-150">
                    <img className="group-151" alt="Group" src="/img/group-2.png" />
                    <div className="group-152">
                      <div className="ellipse-67" />
                    </div>
                    <div className="group-153">
                      <div className="ellipse-68" />
                    </div>
                    <div className="group-154">
                      <div className="overlap-group-16">
                        <div className="ellipse-69" />
                        <div className="rectangle-36" />
                      </div>
                    </div>
                    <img className="subtract-14" alt="Subtract" src="/img/subtract-5.svg" />
                    <div className="frame-455">
                      <div className="rectangle-37" />
                      <div className="ellipse-70" />
                    </div>
                  </div>
                </div>
                <div className="frame-456">
                  <div className="text-wrapper-249">Stake</div>
                  <div className="text-wrapper-250">About</div>
                  <div className="text-wrapper-251">Roadmap</div>
                  <div className="text-wrapper-252">Tokenomic</div>
                </div>
                <div className="frame-457">
                  <div className="frame-458">
                    <div className="twitter-12">
                      <X6 className="x-8" color="white" />
                    </div>
                    <img className="discord-14" alt="Discord" src="/img/discord.svg" />
                  </div>
                  <Wallet
                    className="wallet-33"
                    override={<Iconify1 className="iconify-1" />}
                    phantomPhantomClassName="design-component-instance-node-4"
                    property1="phantom-wallet-connected"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
