import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { X6 } from "../../icons/X6";
import "./style.css";

export const Footer = forwardRef(({ className, elementGooberAllClassName, discord = "/img/discord.svg" }, ref) => {
  return (
    <div ref={ref} className={`footer ${className}`}>
      <div className="frame-18">
        <div className="frame-19">
          <div className="frame-20">
            <div className="frame-21">
              <div className="frame-22">
                <div className="text-wrapper-13">Explore More</div>
              </div>
              <div className="frame-23">
                <div className="text-wrapper-14">Stake</div>
                <div className="text-wrapper-15">About</div>
                <div className="text-wrapper-15">Roadmap</div>
                <div className="text-wrapper-15">Tokenomic</div>
              </div>
            </div>
          </div>
          <div className="frame-24">
            <div className="frame-25">
              <div className="text-wrapper-16">Community</div>
              <div className="group">
                <div className="twitter-2">
                  <X6 className="x-6" color="white" />
                </div>
                <a href="https://discord.gg/WXPbQbByGK" target="_blank" className="link-block-2 _2 w-inline-block">
                  <img className="discord" alt="Discord" src={discord} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="element-goober-all-wrapper">
          <p className={`element-goober-all ${elementGooberAllClassName}`}>
            <span className="text-wrapper-17">©</span>
            <span className="text-wrapper-18">2024 Goober All rights reserved | </span>
            <span className="text-wrapper-19">Terms & Conditions</span>
          </p>
        </div>
      </div>
    </div>
  );
});

Footer.propTypes = {
  discord: PropTypes.string,
};

export default Footer;
