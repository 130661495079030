/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const X6 = ({ color = "white", className }) => {
  return (
    <a href="https://twitter.com/goobergoo_" target="_blank" class="link-block-2 w-inline-block">
    <svg
      className={`x-6 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M5.99386 8.11503L0 0.666992H4.74756L8.44753 5.27039L12.4004 0.687719H15.0151L9.71175 6.84331L16 14.667H11.2666L7.2603 9.6887L2.98317 14.6532H0.354296L5.99386 8.11503ZM11.9565 13.287L2.91105 2.04698H4.0571L13.0912 13.287H11.9565Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
    </a>
  );
};

X6.propTypes = {
  color: PropTypes.string,
};
