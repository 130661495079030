/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Iconify100 = ({ className }) => {
  return (
    <svg
      className={`iconify-100 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_102_12472)">
        <path
          className="path"
          d="M3.70268 3.44674L7.40535 5.9154C7.58803 6.03712 7.73783 6.20207 7.84145 6.39559C7.94507 6.58911 7.99931 6.80522 7.99935 7.02473V14.0014C7.99937 14.1221 7.96662 14.2405 7.9046 14.3441C7.84258 14.4476 7.75361 14.5324 7.64719 14.5893C7.54077 14.6463 7.42089 14.6732 7.30034 14.6674C7.17978 14.6615 7.06308 14.6231 6.96268 14.5561L3.26002 12.0881C3.07724 11.9663 2.92739 11.8012 2.82376 11.6076C2.72013 11.4139 2.66595 11.1977 2.66602 10.9781V4.0014C2.666 3.88071 2.69875 3.76227 2.76077 3.65873C2.82279 3.55519 2.91175 3.47043 3.01817 3.41349C3.12459 3.35655 3.24447 3.32956 3.36503 3.33542C3.48558 3.34128 3.60228 3.37975 3.70268 3.44674ZM5.68468 2.08007C5.79113 2.02311 5.91104 1.99613 6.03163 2.002C6.15221 2.00787 6.26894 2.04637 6.36935 2.1134L10.072 4.58207C10.2547 4.70379 10.4045 4.86873 10.5081 5.06225C10.6117 5.25577 10.666 5.47188 10.666 5.6914V12.6681C10.666 12.7888 10.6333 12.9072 10.5713 13.0107C10.5092 13.1143 10.4203 13.199 10.3139 13.256C10.2074 13.3129 10.0876 13.3399 9.967 13.3341C9.84645 13.3282 9.72975 13.2897 9.62935 13.2227L8.99935 12.8027V7.02473C8.9993 6.6406 8.9044 6.26241 8.7231 5.92375C8.54179 5.58509 8.27967 5.29643 7.96002 5.0834L5.33268 3.3314V2.66807C5.33263 2.54738 5.36535 2.42893 5.42734 2.32538C5.48934 2.22182 5.57828 2.13704 5.68468 2.08007ZM9.03602 0.780069L12.7387 3.24874C12.9214 3.37046 13.0712 3.5354 13.1748 3.72892C13.2784 3.92244 13.3326 4.13855 13.3327 4.35807V11.3347C13.3327 11.4554 13.3 11.5739 13.2379 11.6774C13.1759 11.7809 13.0869 11.8657 12.9805 11.9227C12.8741 11.9796 12.7542 12.0066 12.6337 12.0007C12.5131 11.9949 12.3964 11.9564 12.296 11.8894L11.666 11.4694V5.6914C11.666 5.30726 11.5711 4.92908 11.3898 4.59042C11.2085 4.25176 10.9463 3.9631 10.6267 3.75007L7.99935 1.99807V1.33474C7.99933 1.21404 8.03208 1.0956 8.0941 0.992063C8.15612 0.888521 8.24509 0.803759 8.3515 0.746819C8.45792 0.68988 8.57781 0.662898 8.69836 0.668754C8.81891 0.67461 8.93562 0.713083 9.03602 0.780069Z"
          fill="#FFBA15"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_102_12472">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
