import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Staked } from "./screens/Staked";
import { MobileNav } from "./screens/MobileNav";
import { SuccessShareOnTw } from "./screens/SuccessShareOnTw";
import { StakePageTiers } from "./screens/StakePageTiers";
import { ConnectWallet } from "./screens/ConnectWallet";
import { Typing } from "./screens/Typing";
import { Stake } from "./screens/Stake";
import { StakeTiers } from "./screens/StakeTiers";
import { StakeInNuts } from "./screens/StakeInNuts";
import { StakePageTiersScreen } from "./screens/StakePageTiersScreen";
import { StakeScreen } from "./screens/StakeScreen";
import { StakeInSol } from "./screens/StakeInSol";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <StakePageTiers />,
  },
  {
    path: "/staked",
    element: <Staked />,
  },
  {
    path: "/mobile-nav-status1",
    element: <MobileNav />,
  },
  {
    path: "/success-share-on-tw",
    element: <SuccessShareOnTw />,
  },
  {
    path: "/stake-page-tiers-1",
    element: <StakePageTiers />,
  },
  {
    path: "/connect-wallet",
    element: <ConnectWallet />,
  },
  {
    path: "/typing",
    element: <Typing />,
  },
  {
    path:
      "/u24050u38142u25509u38065u21253u65292u20294u26410stakeu44-u24403u38065u21253u31526u21512u25152u26377u36164u26684-1",
    element: <Stake />,
  },
  {
    path:
      "/u24050u38142u25509u38065u21253u65292u20294u26410stakeu44-u24403u38065u21253u20165u31526u21512u26576u20010tiers",
    element: <StakeTiers />,
  },
  {
    path: "/stake-in-nuts",
    element: <StakeInNuts />,
  },
  {
    path: "/stake-page-tiers",
    element: <StakePageTiersScreen />,
  },
  {
    path:
      "/u24050u38142u25509u38065u21253u65292u20294u26410stakeu44-u24403u38065u21253u31526u21512u25152u26377u36164u26684",
    element: <StakeScreen />,
  },
  {
    path: "/stake-in-sol",
    element: <StakeInSol />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
