/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const X15 = ({ className }) => {
  return (
    <svg
      className={`x-15 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 19 18"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M7.24309 9.12904L0.5 0.75H5.84101L10.0035 5.92882L14.4504 0.773318H17.3919L11.4257 7.69836L18.5 16.5H13.1749L8.66784 10.8994L3.85607 16.4845H0.898583L7.24309 9.12904ZM13.9511 14.9475L3.77493 2.30249H5.06424L15.2276 14.9475H13.9511Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
