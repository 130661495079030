/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1StarBoldDoutone = ({ className }) => {
  return (
    <svg
      className={`property-1-star-bold-doutone ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18.4832 16.7661C17.1164 17.0943 15.6895 17.0784 14.3303 16.7198C12.9712 16.3612 11.7221 15.6712 10.6951 14.7114C9.66804 13.7516 8.89506 12.5521 8.44535 11.2203C7.99564 9.88853 7.88325 8.46598 8.11824 7.08008C8.0825 7.11459 8.04477 7.14698 8.00524 7.17708C7.72523 7.39008 7.37524 7.46908 6.67524 7.62708L6.04024 7.77108C3.58024 8.32808 2.35024 8.60608 2.05723 9.54708C1.76523 10.4871 2.60324 11.4681 4.28024 13.4291L4.71424 13.9361C5.19024 14.4931 5.42923 14.7721 5.53623 15.1161C5.64323 15.4611 5.60723 15.8331 5.53523 16.5761L5.46923 17.2531C5.21623 19.8701 5.08924 21.1781 5.85524 21.7591C6.62123 22.3411 7.77323 21.8111 10.0752 20.7501L10.6722 20.4761C11.3262 20.1741 11.6532 20.0241 12.0002 20.0241C12.3472 20.0241 12.6742 20.1741 13.3292 20.4761L13.9242 20.7501C16.2272 21.8101 17.3792 22.3401 18.1442 21.7601C18.9112 21.1781 18.7842 19.8701 18.5312 17.2531L18.4832 16.7661Z"
        fill="#FFBA15"
      />
      <path
        className="path"
        d="M9.1527 5.408L8.8247 5.996C8.4647 6.642 8.2847 6.965 8.0047 7.178C8.0447 7.148 8.0817 7.116 8.1177 7.081C7.88265 8.46701 7.99504 9.88967 8.4448 11.2216C8.89455 12.5535 9.66763 13.7531 10.6948 14.7128C11.722 15.6726 12.9712 16.3627 14.3305 16.7212C15.6898 17.0797 17.1168 17.0954 18.4837 16.767L18.4637 16.577C18.3927 15.834 18.3567 15.462 18.4637 15.117C18.5707 14.773 18.8087 14.494 19.2857 13.937L19.7197 13.43C21.3967 11.47 22.2347 10.489 21.9417 9.548C21.6497 8.607 20.4197 8.328 17.9597 7.772L17.3237 7.628C16.6247 7.47 16.2747 7.391 15.9937 7.178C15.7137 6.965 15.5337 6.642 15.1737 5.996L14.8467 5.408C13.5797 3.136 12.9467 2 11.9997 2C11.0527 2 10.4197 3.136 9.1527 5.408Z"
        fill="#FFBA15"
        opacity="0.5"
      />
    </svg>
  );
};
