/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Iconify11 } from "../../icons/Iconify11";
import { Iconify26 } from "../../icons/Iconify26";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { PropertyMoney } from "../PropertyMoney";
import { PropertyNuts } from "../PropertyNuts";
import { PropertyTarget } from "../PropertyTarget";
import { PropertyWrapper } from "../PropertyWrapper";
import { Wallet } from "../Wallet";
import "./style.css";

export const Tiers = ({
  className,
  propertyTargetIcon = <Iconify11 className="property-1-money-bill-wave-alt" />,
  text = "10,000,000 NUTS",
  divClassName,
  text1 = "0.00186 SOL",
  walletIcon = <Iconify26 className="iconify-26" color="#FFBA15" />,
  override = <PropertyNuts className="property-1-NUTS" image="/img/image-1-1-2x.png" />,
  text2 = "250,000 GOO",
  icon = <Icons54 className="icon-instance-node" />,
  stakeNutsForFreeClassName,
  spanClassName,
  text3 = "Stake $NUTS for ",
  spanClassNameOverride,
  text4 = "Free $GOO Airdrop",
  icon1 = <Icons53 className="icon-instance-node" />,
  to,
}) => {
  return (
    <div className={`tiers ${className}`}>
      <div className="frame-9">
        <div className="frame-10">
          <div className="frame-11">
            <div className="frame-12">
              {icon}
              {override}
            </div>
            <p className={`stake-NUTS-for-free ${stakeNutsForFreeClassName}`}>
              <span className={`span ${spanClassName}`}>{text3}</span>
              <span className={`text-wrapper-9 ${spanClassNameOverride}`}>{text4}</span>
            </p>
          </div>
          <div className="text-wrapper-10">0%</div>
        </div>
        <PropertyWrapper className="component-5508" property1="one" />
      </div>
      <div className="frame-13">
        <div className="frame-14">
          <div className="frame-14">
            <div className="frame-15">
              <PropertyTarget icon={propertyTargetIcon} />
              <div className="frame-16">
                <div className="text-wrapper-11">Hard Cap</div>
                <div className="frame-17">
                  <div className="text-wrapper-12">{text}</div>
                </div>
              </div>
            </div>
            <div className="frame-15">
              {icon1}
              <div className="frame-16">
                <div className="text-wrapper-11">Amount of GOO</div>
                <div className="frame-17">
                  <div className="text-wrapper-12">{text2}</div>
                </div>
              </div>
            </div>
            <div className="frame-15">
              <PropertyMoney iconify63StyleOverrideClassName="property-1-money-bill-wave-alt" />
              <div className="frame-16">
                <div className="text-wrapper-11">Price</div>
                <div className="frame-17">
                  <div className={`text-wrapper-12 ${divClassName}`}>{text1}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Wallet
          className="wallet-instance"
          divClassName="design-component-instance-node"
          icon={walletIcon}
          property1="wallet-connect"
          text="Connect Wallet to Stake"
          to={to}
        />
      </div>
    </div>
  );
};

Tiers.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  to: PropTypes.string,
};
