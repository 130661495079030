import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { Iconify11 } from "../../icons/Iconify11";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { X6 } from "../../icons/X6";
import "./style.css";

export const StakeTiers = () => {
  return (
    <div className="stake-tiers">
      <div className="overlap-wrapper">
        <div className="overlap-7">
          <div className="frame-341">
            <div className="frame-342">
              <div className="frame-343">
                <div className="group-111">
                  <div className="frame-344">
                    <div className="text-wrapper-185">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-345">
                  <p className="stake-to-mint-GOO-10">
                    <span className="text-wrapper-186">Stake to mint </span>
                    <span className="text-wrapper-187">$GOO</span>
                  </p>
                  <p className="text-wrapper-188">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-346">
                <div className="frame-347">
                  <div className="text-wrapper-189">Total GOO for Mint</div>
                  <div className="frame-348">
                    <div className="text-wrapper-190">10,000,000 GOO</div>
                  </div>
                </div>
                <div className="frame-349">
                  <div className="text-wrapper-189">Staking Period</div>
                  <div className="frame-348">
                    <div className="text-wrapper-190">3 Days</div>
                  </div>
                </div>
                <div className="frame-350">
                  <div className="text-wrapper-189">Total NUTS Staked</div>
                  <div className="frame-348">
                    <div className="text-wrapper-190">10,000 NUTS</div>
                  </div>
                </div>
                <div className="frame-351">
                  <div className="text-wrapper-189">Total SOL Staked</div>
                  <div className="frame-348">
                    <div className="text-wrapper-190">1,000 SOL</div>
                  </div>
                </div>
              </div>
              <div className="user-profile-5">
                <div className="nuts-5">
                  <PropertyNuts className="icons-33" image="/img/image-1-1.png" imageClassName="icons-34" />
                  <div className="element-9">
                    <div className="text-wrapper-189">Your Total Staked NUTS</div>
                    <div className="frame-348">
                      <div className="text-wrapper-190">0 NUTS</div>
                    </div>
                  </div>
                </div>
                <div className="sol-6">
                  <PropertySol className="icons-33" imageClassName="icons-35" />
                  <div className="element-10">
                    <div className="text-wrapper-189">Your Total Staked SOL</div>
                    <div className="frame-348">
                      <div className="text-wrapper-190">0 SOL</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-352">
              <Tabs
                className="tabs-11"
                iconify29Color="#D4D4D4"
                iconify77Color="#FFBA15"
                img="/img/vector-10-1.svg"
                property1="tabs-tiers"
                vector="/img/vector-10.svg"
              />
              <Link className="tiers-9" to="/stake-in-nuts">
                <div className="frame-353">
                  <div className="frame-354">
                    <div className="frame-355">
                      <div className="frame-356">
                        <Icons54 className="icons-37" />
                        <PropertyNuts className="icons-38" image="/img/image-1-1-2x.png" />
                      </div>
                      <p className="div-13">
                        <span className="text-wrapper-191">Stake $NUTS for </span>
                        <span className="text-wrapper-192">Free $GOO Airdrop</span>
                      </p>
                    </div>
                    <div className="text-wrapper-193">0%</div>
                  </div>
                  <PropertyWrapper className="view-5" property1="one" />
                </div>
                <div className="frame-357">
                  <div className="frame-358">
                    <div className="frame-358">
                      <div className="frame-359">
                        <PropertyTarget icon={<Iconify11 className="iconify-92" />} />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Hard Cap</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">Unlimited</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-359">
                        <Icons53 className="icons-37" />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Amount of GOO</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-359">
                        <PropertyMoney iconify63StyleOverrideClassName="iconify-92" />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Price</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">Free</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-22"
                    divClassName="wallet-23"
                    property1="wallet-connect"
                    text="Wallet Not Qualified"
                    visible={false}
                  />
                </div>
              </Link>
              <div className="tiers-9">
                <div className="frame-353">
                  <div className="frame-362">
                    <div className="frame-363">
                      <div className="frame-356">
                        <Icons54 className="icons-37" />
                        <PropertySol className="icons-38" />
                      </div>
                      <p className="div-13">
                        <span className="text-wrapper-191">Stake $SOL for </span>
                        <span className="text-wrapper-192">Whitelist Subscription </span>
                      </p>
                    </div>
                    <div className="text-wrapper-196">0%</div>
                  </div>
                  <PropertyWrapper className="view-5" property1="one" />
                </div>
                <div className="frame-357">
                  <div className="frame-358">
                    <div className="frame-358">
                      <div className="frame-359">
                        <PropertyTarget icon={<Iconify11 className="iconify-92" />} />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Hard Cap</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">6,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-359">
                        <Icons53 className="icons-37" />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Amount of GOO</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">3,500,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-359">
                        <PropertyMoney iconify63StyleOverrideClassName="iconify-92" />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Price</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">0.0185 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-22"
                    divClassName="wallet-23"
                    property1="wallet-connect"
                    text="Wallet Not Qualified"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-9">
                <div className="frame-353">
                  <div className="frame-362">
                    <div className="frame-363">
                      <div className="frame-356">
                        <Icons54 className="icons-37" />
                        <PropertySol className="icons-38" />
                      </div>
                      <p className="stake-NUTS-for-free-9">Stake $SOL for Public Subscription</p>
                    </div>
                    <div className="text-wrapper-196">0%</div>
                  </div>
                  <PropertyWrapper className="view-5" property1="one" />
                </div>
                <div className="frame-357">
                  <div className="frame-358">
                    <div className="frame-358">
                      <div className="frame-359">
                        <PropertyTarget icon={<Iconify11 className="iconify-92" />} />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Hard Cap</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">3,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-359">
                        <Icons53 className="icons-37" />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Amount of GOO</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">1,250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-359">
                        <PropertyMoney iconify63StyleOverrideClassName="iconify-92" />
                        <div className="frame-360">
                          <div className="text-wrapper-194">Price</div>
                          <div className="frame-361">
                            <div className="text-wrapper-195">0.0028 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-24"
                    divClassName="wallet-25"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer className="footer-11" discord="/img/discord.svg" elementGooberAllClassName="footer-12" />
          <div className="nav-13">
            <div className="nav-14">
              <div className="group-112">
                <div className="logo-11">
                  <div className="group-113">
                    <div className="frame-364">
                      <div className="group-114">
                        <div className="group-115">
                          <div className="ellipse-51" />
                        </div>
                        <div className="group-116">
                          <div className="ellipse-51" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-117">
                    <img className="group-118" alt="Group" src="/img/group-2.png" />
                    <div className="group-119">
                      <div className="ellipse-52" />
                    </div>
                    <div className="group-120">
                      <div className="ellipse-53" />
                    </div>
                    <div className="group-121">
                      <div className="overlap-group-13">
                        <div className="ellipse-54" />
                        <div className="rectangle-29" />
                      </div>
                    </div>
                    <img className="subtract-11" alt="Subtract" src="/img/subtract-5.svg" />
                    <div className="frame-365">
                      <div className="rectangle-30" />
                      <div className="ellipse-55" />
                    </div>
                  </div>
                </div>
                <div className="frame-366">
                  <div className="text-wrapper-197">Stake</div>
                  <div className="text-wrapper-198">About</div>
                  <div className="text-wrapper-199">Roadmap</div>
                  <div className="text-wrapper-200">Tokenomic</div>
                </div>
                <div className="frame-367">
                  <div className="frame-368">
                    <div className="twitter-9">
                      <X6 className="x-5" color="white" />
                    </div>
                    <img className="discord-11" alt="Discord" src="/img/discord.svg" />
                  </div>
                  <Wallet className="wallet-26" property1="phantom-wallet-connected" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
