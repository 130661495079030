/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Iconify1 } from "../../icons/Iconify1";
import { Iconify26 } from "../../icons/Iconify26";
import { Phantom } from "../Phantom";
import "./style.css";

export const Wallet = ({
  property1,
  className,
  icon = <Iconify26 className="iconify-2" color="white" />,
  divClassName,
  text = "Connect Wallet",
  visible = true,
  phantomPhantomClassName,
  override = <Iconify1 className="iconify-2" />,
  to,
}) => {
  return (
    <Link className={`wallet ${property1} ${className}`} to={to}>
      {property1 === "expand" && (
        <>
          <div className="div-2">
            <div className="frame-5">
              <Phantom className="phantom-instance" />
              <div className="text-wrapper-5">BRBk...sLJm</div>
            </div>
            <Iconify1 className="iconify-2" />
          </div>
          <div className="frame-wrapper">
            <div className="frame-6">
              <div className="div-wrapper">
                <div className="frame-7">
                  <div className="text-wrapper-6">Copy Address</div>
                </div>
              </div>
              <div className="frame-8">
                <div className="frame-7">
                  <div className="text-wrapper-7">Change Wallet</div>
                </div>
              </div>
              <div className="div-wrapper">
                <div className="frame-7">
                  <div className="text-wrapper-8">Disconnect</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {["phantom-wallet-connected", "wallet-connect"].includes(property1) && (
        <div className="frame-5">
          {property1 === "phantom-wallet-connected" && (
            <>
              <Phantom className={phantomPhantomClassName} />
              <div className="text-wrapper-5">BRBk...sLJm</div>
            </>
          )}

          {property1 === "wallet-connect" && (
            <>
              <>{visible && <>{icon}</>}</>
              <div className={`text-wrapper-5 ${divClassName}`}>{text}</div>
            </>
          )}
        </div>
      )}

      {property1 === "phantom-wallet-connected" && <>{override}</>}
    </Link>
  );
};

Wallet.propTypes = {
  property1: PropTypes.oneOf(["phantom-wallet-connected", "expand", "wallet-connect"]),
  text: PropTypes.string,
  visible: PropTypes.bool,
  to: PropTypes.string,
};
