import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { Footer } from "../../components/Footer";
import { FooterWrapper } from "../../components/FooterWrapper";
import { Icons } from "../../components/Icons";
import { MobileTiers } from "../../components/MobileTiers";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { StatusBar } from "../../components/StatusBar";
import { Tabs } from "../../components/Tabs";
import { Tiers } from "../../components/Tiers";
import { Wallet } from "../../components/Wallet";
import {FAQs} from '../../components/FAQs';
import { WalletWrapper } from "../../components/WalletWrapper";
import {ConnectButton} from '../../components/ConnectWallet/ConnectButton';
import { Iconify100 } from "../../icons/Iconify100";
import { Iconify101 } from "../../icons/Iconify101";
import { Iconify11 } from "../../icons/Iconify11";
import { Iconify26 } from "../../icons/Iconify26";
import { Iconify62 } from "../../icons/Iconify62";
import { Icons21 } from "../../icons/Icons21";
import { Icons25 } from "../../icons/Icons25";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import WalletContextProvider from '../../components/ConnectWallet/WalletContextProvider'

import { X6 } from "../../icons/X6";
import CountdownTimer from '../../components/CountdownTimer';
import React, { useState , useEffect, useRef } from 'react';



import "./style.css";

export const StakePageTiers = () => {
  const screenWidth = useWindowWidth();
  const [activeTab, setActiveTab] = useState('tabs-tiers');

  const footerRef = useRef(null);
  const divRef = useRef(null);

  useEffect(() => {
    const updateFooterPosition = () => {
      if (divRef.current && footerRef.current) {
        const contentHeight = divRef.current.offsetHeight;
        footerRef.current.style.top = `${contentHeight + 20}px`;
      }
    };

    updateFooterPosition();
    window.addEventListener('resize', updateFooterPosition);

    return () => window.removeEventListener('resize', updateFooterPosition);
  }, []);

  return (
    <WalletContextProvider>
    <div
      className="stake-page-tiers"
      style={{
        backgroundColor: screenWidth < 1920 ? "transparent" : screenWidth >= 1920 ? "#ffffff" : undefined,
      }}
    >
      <div
        ref={divRef}
        className="div-8"
        style={{
          backgroundColor: screenWidth >= 1920 ? "#ffffff" : undefined,
          height: screenWidth < 1920 ? "1281px" : screenWidth >= 1920 ? "2083px" : undefined,
          overflow: screenWidth < 1920 ? "hidden" : undefined,
          width: screenWidth < 1920 ? "375px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        {screenWidth < 1920 && (
          <>
            <div className="overlap-2">
              <div className="frame-155">
                <div className="group-43">
                  <div className="frame-156">
                    <div className="text-wrapper-93">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-157">
                  <p className="stake-to-mint-GOO-4">
                    <span className="text-wrapper-94">Stake to mint </span>
                    <span className="text-wrapper-95">$GOO</span>
                  </p>
                  <p className="text-wrapper-96">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-158">
                <div className="frame-159">
                  <div className="frame-160">
                    <div className="text-wrapper-97">Total GOO for Mint</div>
                    <div className="frame-161">
                      <div className="text-wrapper-98">10,000,000 GOO</div>
                    </div>
                  </div>
                  <div className="frame-162">
                    <div className="text-wrapper-97">Staking Period</div>
                    <div className="frame-161">
                        <div>
                        <CountdownTimer targetDate="2024-04-25T00:00:00"/>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="frame-159">
                  <div className="frame-160">
                    <div className="text-wrapper-97">Total NUTS Staked</div>
                    <div className="frame-161">
                      <div className="text-wrapper-98">10,000 NUTS</div>
                    </div>
                  </div>
                  <div className="frame-162">
                    <div className="text-wrapper-97">Total SOL Staked</div>
                    <div className="frame-161">
                      <div className="text-wrapper-98">1,000 SOL</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-163">
                <div className="group-44">
                  <img className="vector-3" alt="Vector" src="/img/vector-11-6.svg" />
                  <div className="frame-164">
                    <div className="frame-165">
                      <Iconify100 className="iconify-3" />
                      <div className="text-wrapper-99">Tiers</div>
                    </div>
                    <img className="vector-4" alt="Vector" src="/img/vector-10-12.svg" />
                  </div>
                  <div className="frame-166">
                    <div className="frame-165">
                      <Iconify101 className="iconify-3" />
                      <div className="text-wrapper-100">Rules</div>
                    </div>
                    <img className="vector-5" alt="Vector" src="/img/vector-10-13.svg" />
                  </div>
                </div>
                <MobileTiers
                  className="instance-node-4"
                  propertyTargetIcon={<Iconify62 className="instance-node-5" />}
                  walletWrapperDivClassName="mobile-tiers-instance"
                />
                <MobileTiers
                  className="instance-node-4"
                  iconsProperty1="sol"
                  iconsPropertyNutClassName="instance-node-6"
                  propertyTargetIcon={<Iconify62 className="instance-node-5" />}
                  text="Stake $SOL for"
                  text1=" Whitelist Subscription "
                  text2="6,500 SOL"
                  text3="3,500,000 GOO"
                  text4="0.0185 SOL"
                />
                <div className="mobile-tiers-2">
                  <div className="frame-167">
                    <div className="frame-168">
                      <div className="frame-169">
                        <div className="frame-170">
                          <Icons25 className="icons-44" />
                          <Icons className="instance-node-6" property1="sol" />
                        </div>
                      </div>
                      <p className="stake-NUTS-for-free-4">Stake $SOL for Public Subscription</p>
                    </div>
                    <div className="frame-171">
                      <div className="frame-172">
                        <div className="text-wrapper-101">0%</div>
                        <div className="group-45">
                          <div className="rectangle-12" />
                        </div>
                      </div>
                      <div className="frame-173">
                        <div className="frame-174">
                          <div className="frame-175">
                            <PropertyTarget className="icons-13" icon={<Iconify62 className="instance-node-5" />} />
                            <div className="frame-176">
                              <div className="text-wrapper-102">Hard Cap</div>
                              <div className="frame-177">
                                <div className="text-wrapper-103">3,500 SOL</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-178">
                            <Icons21 className="icons-43" />
                            <div className="frame-176">
                              <div className="text-wrapper-102">Amount of GOO</div>
                              <div className="frame-177">
                                <div className="text-wrapper-103">1,250,000 GOO</div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-179">
                            <PropertyMoney className="icons-13" iconify63StyleOverrideClassName="instance-node-5" />
                            <div className="frame-176">
                              <div className="text-wrapper-102">Price</div>
                              <div className="frame-177">
                                <div className="text-wrapper-103">0.0028 SOL</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <WalletWrapper />
                </div>
              </div>
              <FooterWrapper className="footer-5" />
            </div>
            <div className="frame-180">
              <StatusBar
                className="status-bar-3"
                darkMode={false}
                dynIslSize="default"
                dynamicIsland
                iconMobileSignal="/img/icon-mobile-signal-8.svg"
                statusbarTimeTimeClassName="status-bar-4"
                wifi="/img/wifi-4.svg"
              />
              <div className="nav-5">
                <div className="logo-5">
                  <div className="group-46">
                    <div className="frame-181">
                      <div className="group-47">
                        <div className="group-48">
                          <div className="ellipse-21" />
                        </div>
                        <div className="group-49">
                          <div className="ellipse-21" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-50">
                    <img className="group-51" alt="Group" src="/img/group-2-5.png" />
                    <div className="group-52">
                      <div className="ellipse-22" />
                    </div>
                    <div className="group-53">
                      <div className="ellipse-23" />
                    </div>
                    <div className="group-54">
                      <div className="overlap-group-7">
                        <div className="ellipse-24" />
                        <div className="rectangle-13" />
                      </div>
                    </div>
                    <img className="subtract-5" alt="Subtract" src="/img/subtract-9.svg" />
                    <div className="frame-182">
                      <div className="rectangle-14" />
                      <div className="ellipse-25" />
                    </div>
                  </div>
                </div>
                <Link to="/mobile-nav-status1">
                  <img className="menu-2" alt="Menu" src="/img/menu.svg" />
                </Link>
              </div>
            </div>
          </>
        )}

        {screenWidth >= 1920 && (
          <>
            <div className="frame-183">
              <div className="frame-184">
                <div className="frame-185">
                  <div className="group-55">
                    <div className="frame-186">
                      <div className="text-wrapper-104">Contract Power by TinySPL</div>
                    </div>
                  </div>
                  <div className="frame-187">
                    <p className="stake-to-mint-GOO-5">
                      <span className="text-wrapper-105">Stake to mint </span>
                      <span className="text-wrapper-106">$GOO</span>
                    </p>
                    <p className="text-wrapper-107">Stake today for earlier supporter airdrop🎁</p>
                  </div>
                </div>
                <div className="frame-188">
                  <div className="frame-189">
                    <div className="text-wrapper-108">Total GOO for Mint</div>
                    <div className="frame-190">
                      <div className="text-wrapper-109">10,000,000 GOO</div>
                    </div>
                  </div>
                  <div className="frame-191">
                    <div className="text-wrapper-108">Staking Period</div>
                    <div className="frame-190">
                    <div>
                      <CountdownTimer targetDate="2024-04-25T00:00:00" />
                    </div>
                    </div>
                  </div>
                  <div className="frame-192">
                    <div className="text-wrapper-108">Total NUTS Staked</div>
                    <div className="frame-190">
                      <div className="text-wrapper-109">10,000 NUTS</div>
                    </div>
                  </div>
                  <div className="frame-193">
                    <div className="text-wrapper-108">Total SOL Staked</div>
                    <div className="frame-190">
                      <div className="text-wrapper-109">1,000 SOL</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-194"> 
                <Tabs
                  setActiveTab={setActiveTab}
                  className="tabs-7"
                  iconify29Color="#D4D4D4"
                  iconify77Color="#FFBA15"
                  img="/img/vector-10-1.svg"
                  activeTab={activeTab}
                  vector="/img/vector-10.svg"
                />
                {activeTab === 'tabs-tiers' && (
                  <>
                    <Tiers
                      className="instance-node-4"
                      divClassName="tiers-instance"
                      propertyTargetIcon={<Iconify11 className="iconify-13" />}
                      text="Unlimited"
                      text1="Free"
                      to="/connect-wallet"
                      walletIcon={<Iconify26 className="iconify-26-instance" color="#FFBA15" />}
                    />
                    <Tiers
                      className="instance-node-4"
                      override={<PropertySol className="icons-14" />}
                      propertyTargetIcon={<Iconify11 className="iconify-13" />}
                      text="6,500 SOL"
                      text1="0.0185 SOL"
                      text2="3,500,000 GOO"
                      walletIcon={<Iconify26 className="iconify-26-instance" color="#FFBA15" />}
                    />
                    <Tiers
                      className="instance-node-4"
                      override={<PropertySol className="icons-14" />}
                      propertyTargetIcon={<Iconify11 className="iconify-13" />}
                      text="3,500 SOL"
                      text1="0.0028 SOL"
                      text2="1,250,000 GOO"
                      walletIcon={<Iconify26 className="iconify-26-instance" color="#FFBA15" />}
                    />
                    </>
                )}
                {activeTab === 'tabs-FAQ' && (
              <>
              <div className="flexcontainer">
                <div className="text-wrapper-59">Subscription Rules</div>
                <ul className="rules-list">
                  <li>
                    All 3 subscription tiers will start and end at the same time.
                  </li>
                  <li>
                    The subscription will last for <span className="text-wrapper-61">3 days</span>.
                  </li>
                  <li>
                    Minimum amount to stake is 2 NUTS/ 0.5 SOL.
                  </li>
                  <li>
                    For <span className="text-wrapper-61">free subscription</span>: all your staked tokens (NUTS) will be returned to your wallet at the end of the period.
                  </li>
                  <li>
                    For <span className="text-wrapper-61">whitelist and public subscription</span>: tokens you staked will be automatically deducted proportionally at the end of the period.
                  </li>
                  <li>
                    In the case of oversubscribed for whitelist and public subscription, your staked tokens will be returned to your wallet proportionally.
                  </li>
                  <li>
                    The amount of GOO you get will be based on the time you stake and the quantity of token you stake verse the total average staked. Snapshot will be taken every hour, a timestamp and the quantity will be recorded when you stake.
                  </li>
                  <li>
                    Once the subscription period start, you can only stake once, and you <span className="text-wrapper-61">cannot unstake or redeem during the subscription period</span>, please make informed decision for your staking amount.
                  </li>
                </ul>
              </div>
              <FAQs />
              

          
              </>
              
                )}
              </div>
            </div>

            <Footer ref={footerRef} className="footer-6" discord="/img/discord-6.svg" elementGooberAllClassName="footer-7" />

            <div className="nav-6">
              <div className="group-56">
                <div className="logo-6">
                  <div className="group-57">
                    <div className="frame-195">
                      <div className="group-58">
                        <div className="group-59">
                          <div className="ellipse-26" />
                        </div>
                        <div className="group-60">
                          <div className="ellipse-26" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-61">
                    <img className="group-62" alt="Group" src="/img/group-2.png" />
                    <div className="group-63">
                      <div className="ellipse-27" />
                    </div>
                    <div className="group-64">
                      <div className="ellipse-28" />
                    </div>
                    <div className="group-65">
                      <div className="overlap-group-8">
                        <div className="ellipse-29" />
                        <div className="rectangle-15" />
                      </div>
                    </div>
                    <img className="subtract-6" alt="Subtract" src="/img/subtract-5.svg" />
                    <div className="frame-196">
                      <div className="rectangle-16" />
                      <div className="ellipse-30" />
                    </div>
                  </div>
                </div>
                <div className="navbar-2">
                  <div className="text-wrapper-112">Stake</div>
                  <div className="text-wrapper-113">About</div>
                  <div className="text-wrapper-114">Roadmap</div>
                  <div className="text-wrapper-115">Tokenomic</div>
                </div>
                <div className="frame-197">
                  <div className="frame-198">
                    <div className="state-default-wrapper">
                      <X6 className="x-12-instance" color="white" />
                    </div>
                    <a href="https://discord.gg/WXPbQbByGK" target="_blank" class="link-block-2 _2 w-inline-block">
                    <img className="discord-7" alt="Discord" src="/img/discord.svg" />
                    </a>
                  </div>
                  {/* <Wallet
                    className="instance-node-4"
                    icon={<Iconify26 className="iconify-26-instance" color="white" />}
                    property1="wallet-connect"
                    text="Connect Wallet"
                  /> */}
                  <ConnectButton/>
                  {/* <WalletMultiButton/> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
    </WalletContextProvider>
  );
};
