/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Statusbar = ({ type, className, truedepthCameraClassName, facetimeCameraClassName }) => {
  return (
    <div className={`statusbar type-0-${type} ${className}`}>
      {["default", "large", "separated", "x-large"].includes(type) && (
        <>
          <div className={`truedepth-camera ${truedepthCameraClassName}`}>
            {type === "separated" && (
              <>
                <div className="truedepth-camera-2" />
                <div className="facetime-camera" />
              </>
            )}
          </div>
          <div className={`facetime-camera-2 ${facetimeCameraClassName}`} />
        </>
      )}

      {type === "ultra" && (
        <div className="overlap-group-2">
          <div className="truedepth-camera-3" />
          <div className="facetime-camera-3" />
          <div className="dynamic-island" />
        </div>
      )}
    </div>
  );
};

Statusbar.propTypes = {
  type: PropTypes.oneOf(["large", "default", "separated", "ultra", "x-large"]),
};
