/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Iconify31 = ({ className }) => {
  return (
    <svg
      className={`iconify-31 ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 34 34"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M14.1667 24.0837L21.25 17.0003L14.1667 9.91699"
        stroke="#202020"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.83333"
      />
    </svg>
  );
};
