import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { Footer } from "../../components/Footer";
import { FooterWrapper } from "../../components/FooterWrapper";
import { Icons } from "../../components/Icons";
import { MobileTiers } from "../../components/MobileTiers";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { StakedInfo } from "../../components/StakedInfo";
import { StatusBar } from "../../components/StatusBar";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { WalletWrapper } from "../../components/WalletWrapper";
import { Iconify100 } from "../../icons/Iconify100";
import { Iconify101 } from "../../icons/Iconify101";
import { Iconify11 } from "../../icons/Iconify11";
import { Iconify62 } from "../../icons/Iconify62";
import { Icons21 } from "../../icons/Icons21";
import { Icons25 } from "../../icons/Icons25";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { X6 } from "../../icons/X6";
import "./style.css";

export const Stake = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="stake">
      <div
        className="stake-3"
        style={{
          height: screenWidth < 1920 ? "1357px" : screenWidth >= 1920 ? "2067px" : undefined,
          overflow: screenWidth >= 1920 ? "hidden" : undefined,
          position: screenWidth < 1920 ? "relative" : undefined,
          width: screenWidth < 1920 ? "375px" : screenWidth >= 1920 ? "1920px" : undefined,
        }}
      >
        {screenWidth < 1920 && (
          <>
            <div className="overlap-5">
              <div className="rectangle-23" />
              <div className="frame-281">
                <div className="frame-282">
                  <div className="frame-283">
                    <div className="frame-284">
                      <div className="group-88">
                        <div className="frame-285">
                          <div className="text-wrapper-158">Contract Power by TinySPL</div>
                        </div>
                      </div>
                      <div className="frame-286">
                        <p className="stake-to-mint-GOO-8">
                          <span className="text-wrapper-159">Stake to mint </span>
                          <span className="text-wrapper-160">$GOO</span>
                        </p>
                        <p className="text-wrapper-161">Stake today for earlier supporter airdrop🎁</p>
                      </div>
                    </div>
                    <div className="frame-287">
                      <div className="frame-288">
                        <div className="frame-289">
                          <div className="text-wrapper-162">Total GOO for Mint</div>
                          <div className="frame-290">
                            <div className="text-wrapper-163">10,000,000 GOO</div>
                          </div>
                        </div>
                        <div className="frame-291">
                          <div className="text-wrapper-162">Staking Period</div>
                          <div className="frame-290">
                            <div className="text-wrapper-163">3 Days</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-292">
                        <div className="frame-289">
                          <div className="text-wrapper-162">Total NUTS Staked</div>
                          <div className="frame-290">
                            <div className="text-wrapper-163">10,000 NUTS</div>
                          </div>
                        </div>
                        <div className="frame-291">
                          <div className="text-wrapper-162">Total SOL Staked</div>
                          <div className="frame-290">
                            <div className="text-wrapper-163">1,000 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <StakedInfo className="instance-node-7" text="0 GOO" text1="0 SOL" />
                </div>
                <div className="frame-293">
                  <div className="group-89">
                    <img className="vector-6" alt="Vector" src="/img/vector-11-6.svg" />
                    <div className="frame-294">
                      <div className="frame-295">
                        <Iconify100 className="iconify-5" />
                        <div className="text-wrapper-164">Tiers</div>
                      </div>
                      <img className="vector-7" alt="Vector" src="/img/vector-10-12.svg" />
                    </div>
                    <div className="frame-296">
                      <div className="frame-295">
                        <Iconify101 className="iconify-5" />
                        <div className="text-wrapper-165">Rules</div>
                      </div>
                      <img className="vector-8" alt="Vector" src="/img/vector-10-13.svg" />
                    </div>
                  </div>
                  <MobileTiers
                    className="mobile-tiers-3"
                    iconsProperty1="nut"
                    iconsPropertyNutClassName="mobile-tiers-4"
                    propertyTargetIcon={<Iconify62 className="instance-node-9" />}
                    text="Stake $NUTS for "
                    text1="Free $GOO Airdrop"
                    text2="Unlimited"
                    text3="250,000 GOO"
                    text4="Free"
                    walletWrapperDivClassName="mobile-tiers-5"
                    walletWrapperText="Stake Now"
                    walletWrapperWalletClassName="instance-node-8"
                  />
                  <MobileTiers
                    className="mobile-tiers-6"
                    iconsProperty1="sol"
                    iconsPropertyNutClassName="instance-node-10"
                    propertyTargetIcon={<Iconify62 className="instance-node-9" />}
                    text="Stake $SOL for"
                    text1=" Whitelist Subscription "
                    text2="6,500 SOL"
                    text3="3,500,000 GOO"
                    text4="0.0185 SOL"
                    walletWrapperDivClassName="mobile-tiers-5"
                    walletWrapperText="Stake Now"
                    walletWrapperWalletClassName="instance-node-8"
                  />
                  <div className="mobile-tiers-7">
                    <div className="frame-297">
                      <div className="frame-298">
                        <div className="frame-299">
                          <div className="frame-300">
                            <Icons25 className="icons-40" />
                            <Icons className="instance-node-10" property1="sol" />
                          </div>
                        </div>
                        <p className="stake-NUTS-for-free-7">Stake $SOL for Public Subscription</p>
                      </div>
                      <div className="frame-301">
                        <div className="frame-302">
                          <div className="text-wrapper-166">0%</div>
                          <div className="group-90">
                            <div className="rectangle-24" />
                          </div>
                        </div>
                        <div className="frame-303">
                          <div className="frame-304">
                            <div className="frame-305">
                              <PropertyTarget className="icons-24" icon={<Iconify62 className="instance-node-9" />} />
                              <div className="frame-306">
                                <div className="text-wrapper-167">Hard Cap</div>
                                <div className="frame-307">
                                  <div className="text-wrapper-168">3,500 SOL</div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-308">
                              <Icons21 className="icons-27" />
                              <div className="frame-306">
                                <div className="text-wrapper-167">Amount of GOO</div>
                                <div className="frame-307">
                                  <div className="text-wrapper-168">1,250,000 GOO</div>
                                </div>
                              </div>
                            </div>
                            <div className="frame-309">
                              <PropertyMoney className="icons-24" iconify63StyleOverrideClassName="instance-node-9" />
                              <div className="frame-306">
                                <div className="text-wrapper-167">Price</div>
                                <div className="frame-307">
                                  <div className="text-wrapper-168">0.0028 SOL</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <WalletWrapper className="instance-node-8" divClassName="wallet-19" text="Stake Now" />
                  </div>
                </div>
              </div>
              <FooterWrapper className="footer-8" />
            </div>
            <div className="frame-310">
              <StatusBar
                className="status-bar-5"
                darkMode={false}
                dynIslSize="default"
                dynamicIsland
                iconMobileSignal="/img/icon-mobile-signal-10.svg"
                statusbarTimeTimeClassName="status-bar-6"
                wifi="/img/wifi-6.svg"
              />
              <div className="nav-10">
                <div className="logo-9">
                  <div className="group-91">
                    <div className="frame-311">
                      <div className="group-92">
                        <div className="group-93">
                          <div className="ellipse-41" />
                        </div>
                        <div className="group-94">
                          <div className="ellipse-41" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-95">
                    <img className="group-96" alt="Group" src="/img/group-2-5.png" />
                    <div className="group-97">
                      <div className="ellipse-42" />
                    </div>
                    <div className="group-98">
                      <div className="ellipse-43" />
                    </div>
                    <div className="group-99">
                      <div className="overlap-group-11">
                        <div className="ellipse-44" />
                        <div className="rectangle-25" />
                      </div>
                    </div>
                    <img className="subtract-9" alt="Subtract" src="/img/subtract-17.svg" />
                    <div className="frame-312">
                      <div className="rectangle-26" />
                      <div className="ellipse-45" />
                    </div>
                  </div>
                </div>
                <img className="menu-3" alt="Menu" src="/img/menu.svg" />
              </div>
            </div>
          </>
        )}

        {screenWidth >= 1920 && (
          <div className="overlap-6">
            <div className="frame-313">
              <div className="frame-314">
                <div className="frame-315">
                  <div className="group-100">
                    <div className="frame-316">
                      <div className="text-wrapper-169">Contract Power by TinySPL</div>
                    </div>
                  </div>
                  <div className="frame-317">
                    <p className="stake-to-mint-GOO-9">
                      <span className="text-wrapper-170">Stake to mint </span>
                      <span className="text-wrapper-171">$GOO</span>
                    </p>
                    <p className="text-wrapper-172">Stake today for earlier supporter airdrop🎁</p>
                  </div>
                </div>
                <div className="frame-318">
                  <div className="frame-319">
                    <div className="text-wrapper-173">Total GOO for Mint</div>
                    <div className="frame-320">
                      <div className="text-wrapper-174">10,000,000 GOO</div>
                    </div>
                  </div>
                  <div className="frame-321">
                    <div className="text-wrapper-173">Staking Period</div>
                    <div className="frame-320">
                      <div className="text-wrapper-174">3 Days</div>
                    </div>
                  </div>
                  <div className="frame-322">
                    <div className="text-wrapper-173">Total NUTS Staked</div>
                    <div className="frame-320">
                      <div className="text-wrapper-174">10,000 NUTS</div>
                    </div>
                  </div>
                  <div className="frame-323">
                    <div className="text-wrapper-173">Total SOL Staked</div>
                    <div className="frame-320">
                      <div className="text-wrapper-174">1,000 SOL</div>
                    </div>
                  </div>
                </div>
                <div className="user-profile-4">
                  <div className="nuts-4">
                    <PropertyNuts className="icons-26" image="/img/image-1-1.png" imageClassName="icons-28" />
                    <div className="element-7">
                      <div className="text-wrapper-173">Your Total Staked NUTS</div>
                      <div className="frame-320">
                        <div className="text-wrapper-174">0 NUTS</div>
                      </div>
                    </div>
                  </div>
                  <div className="sol-5">
                    <PropertySol className="icons-26" imageClassName="icons-29" />
                    <div className="element-8">
                      <div className="text-wrapper-173">Your Total Staked SOL</div>
                      <div className="frame-320">
                        <div className="text-wrapper-174">0 SOL</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                className="frame-324"
                to="/u24050u38142u25509u38065u21253u65292u20294u26410stakeu44-u24403u38065u21253u20165u31526u21512u26576u20010tiers"
              >
                <Tabs
                  className="tabs-10"
                  iconify29Color="#D4D4D4"
                  iconify77Color="#FFBA15"
                  img="/img/vector-10-1.svg"
                  property1="tabs-tiers"
                  vector="/img/vector-10.svg"
                />
                <div className="tiers-8">
                  <div className="frame-325">
                    <div className="frame-326">
                      <div className="frame-327">
                        <div className="frame-328">
                          <Icons54 className="icons-31" />
                          <PropertyNuts className="icons-32" image="/img/image-1-1-2x.png" />
                        </div>
                        <p className="div-12">
                          <span className="text-wrapper-175">Stake $NUTS for </span>
                          <span className="text-wrapper-176">Free $GOO Airdrop</span>
                        </p>
                      </div>
                      <div className="text-wrapper-177">0%</div>
                    </div>
                    <PropertyWrapper className="view-4" property1="one" />
                  </div>
                  <div className="frame-329">
                    <div className="frame-330">
                      <div className="frame-330">
                        <div className="frame-331">
                          <PropertyTarget icon={<Iconify11 className="instance-node-11" />} />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Hard Cap</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">Unlimited</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-331">
                          <Icons53 className="icons-31" />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Amount of GOO</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">250,000 GOO</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-331">
                          <PropertyMoney iconify63StyleOverrideClassName="instance-node-11" />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Price</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">Free</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Wallet
                      className="wallet-20"
                      divClassName="wallet-21"
                      property1="wallet-connect"
                      text="Stake Now"
                      visible={false}
                    />
                  </div>
                </div>
                <div className="tiers-8">
                  <div className="frame-325">
                    <div className="frame-334">
                      <div className="frame-335">
                        <div className="frame-328">
                          <Icons54 className="icons-31" />
                          <PropertySol className="icons-32" />
                        </div>
                        <p className="div-12">
                          <span className="text-wrapper-175">Stake $SOL for </span>
                          <span className="text-wrapper-176">Whitelist Subscription </span>
                        </p>
                      </div>
                      <div className="text-wrapper-180">0%</div>
                    </div>
                    <PropertyWrapper className="view-4" property1="one" />
                  </div>
                  <div className="frame-329">
                    <div className="frame-330">
                      <div className="frame-330">
                        <div className="frame-331">
                          <PropertyTarget icon={<Iconify11 className="instance-node-11" />} />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Hard Cap</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">6,500 SOL</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-331">
                          <Icons53 className="icons-31" />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Amount of GOO</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">3,500,000 GOO</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-331">
                          <PropertyMoney iconify63StyleOverrideClassName="instance-node-11" />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Price</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">0.0185 SOL</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Wallet
                      className="wallet-20"
                      divClassName="wallet-21"
                      property1="wallet-connect"
                      text="Stake Now"
                      visible={false}
                    />
                  </div>
                </div>
                <div className="tiers-8">
                  <div className="frame-325">
                    <div className="frame-334">
                      <div className="frame-335">
                        <div className="frame-328">
                          <Icons54 className="icons-31" />
                          <PropertySol className="icons-32" />
                        </div>
                        <p className="stake-NUTS-for-free-8">Stake $SOL for Public Subscription</p>
                      </div>
                      <div className="text-wrapper-180">0%</div>
                    </div>
                    <PropertyWrapper className="view-4" property1="one" />
                  </div>
                  <div className="frame-329">
                    <div className="frame-330">
                      <div className="frame-330">
                        <div className="frame-331">
                          <PropertyTarget icon={<Iconify11 className="instance-node-11" />} />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Hard Cap</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">3,500 SOL</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-331">
                          <Icons53 className="icons-31" />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Amount of GOO</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">1,250,000 GOO</div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-331">
                          <PropertyMoney iconify63StyleOverrideClassName="instance-node-11" />
                          <div className="frame-332">
                            <div className="text-wrapper-178">Price</div>
                            <div className="frame-333">
                              <div className="text-wrapper-179">0.0028 SOL</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Wallet
                      className="wallet-20"
                      divClassName="wallet-21"
                      property1="wallet-connect"
                      text="Stake Now"
                      visible={false}
                    />
                  </div>
                </div>
              </Link>
            </div>
            <Footer className="footer-9" discord="/img/discord.svg" elementGooberAllClassName="footer-10" />
            <div className="nav-11">
              <div className="nav-12">
                <div className="group-101">
                  <div className="logo-10">
                    <div className="group-102">
                      <div className="frame-336">
                        <div className="group-103">
                          <div className="group-104">
                            <div className="ellipse-46" />
                          </div>
                          <div className="group-105">
                            <div className="ellipse-46" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-106">
                      <img className="group-107" alt="Group" src="/img/group-2.png" />
                      <div className="group-108">
                        <div className="ellipse-47" />
                      </div>
                      <div className="group-109">
                        <div className="ellipse-48" />
                      </div>
                      <div className="group-110">
                        <div className="overlap-group-12">
                          <div className="ellipse-49" />
                          <div className="rectangle-27" />
                        </div>
                      </div>
                      <img className="subtract-10" alt="Subtract" src="/img/subtract-5.svg" />
                      <div className="frame-337">
                        <div className="rectangle-28" />
                        <div className="ellipse-50" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-338">
                    <div className="text-wrapper-181">Stake</div>
                    <div className="text-wrapper-182">About</div>
                    <div className="text-wrapper-183">Roadmap</div>
                    <div className="text-wrapper-184">Tokenomic</div>
                  </div>
                  <div className="frame-339">
                    <div className="frame-340">
                      <div className="twitter-8">
                        <X6 className="x-4" color="white" />
                      </div>
                      <img className="discord-10" alt="Discord" src="/img/discord.svg" />
                    </div>
                    <Wallet className="instance-node-7" property1="phantom-wallet-connected" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
