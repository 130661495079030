/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Twitter } from "../Twitter";
import "./style.css";

export const PropertyTwitter = ({ className, twitterTwitterClassName, twitterX6StyleOverrideClassName }) => {
  return (
    <div className={`property-twitter ${className}`}>
      <Twitter className={twitterTwitterClassName} x6StyleOverrideClassName={twitterX6StyleOverrideClassName} />
    </div>
  );
};
