import React from "react";
import { Footer } from "../../components/Footer";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { Property1StarBoldDoutone } from "../../icons/Property1StarBoldDoutone";
import { X6 } from "../../icons/X6";
import "./style.css";

export const Staked = () => {
  return (
    <div className="staked">
      <div className="div-4">
        <div className="frame-51">
          <div className="frame-52">
            <div className="frame-53">
              <div className="group-3">
                <div className="frame-54">
                  <div className="text-wrapper-34">Contract Power by TinySPL</div>
                </div>
              </div>
              <div className="frame-55">
                <p className="stake-to-mint-GOO">
                  <span className="text-wrapper-35">Stake to mint </span>
                  <span className="text-wrapper-36">$GOO</span>
                </p>
                <p className="text-wrapper-37">Stake today for earlier supporter airdrop🎁</p>
              </div>
            </div>
            <div className="frame-56">
              <div className="frame-57">
                <div className="text-wrapper-38">Total GOO for Mint</div>
                <div className="frame-58">
                  <div className="text-wrapper-39">10,000,000 GOO</div>
                </div>
              </div>
              <div className="frame-59">
                <div className="text-wrapper-38">Staking Period</div>
                <div className="frame-58">
                  <p className="element-d-hr-min">
                    <span className="text-wrapper-40">2 </span>
                    <span className="text-wrapper-41">D</span>
                    <span className="text-wrapper-40"> 12 </span>
                    <span className="text-wrapper-41">Hr</span>
                    <span className="text-wrapper-40"> 50 </span>
                    <span className="text-wrapper-41">Min</span>
                    <span className="text-wrapper-40"> 5 </span>
                    <span className="text-wrapper-41">Sec</span>
                  </p>
                </div>
              </div>
              <div className="frame-60">
                <div className="text-wrapper-38">Total NUTS Staked</div>
                <div className="frame-58">
                  <div className="text-wrapper-39">10,000 NUTS</div>
                </div>
              </div>
              <div className="frame-61">
                <div className="text-wrapper-38">Total SOL Staked</div>
                <div className="frame-58">
                  <div className="text-wrapper-39">2 SOL</div>
                </div>
              </div>
            </div>
            <div className="user-profile">
              <div className="nuts">
                <PropertyNuts className="icons-5" image="/img/image-1-1.png" imageClassName="icons-6" />
                <div className="element">
                  <div className="text-wrapper-38">Your Total Staked NUTS</div>
                  <div className="frame-58">
                    <div className="text-wrapper-39">100.123 NUTS</div>
                  </div>
                </div>
              </div>
              <div className="sol-2">
                <PropertySol className="icons-5" imageClassName="property-SOL-instance" />
                <div className="element-2">
                  <div className="text-wrapper-38">Your Total Staked SOL</div>
                  <div className="frame-58">
                    <div className="text-wrapper-39">2 SOL</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-62">
            <Tabs className="tabs-instance" iconify29Color="#D4D4D4" property1="tabs-tiers" />
            <div className="tiers-2">
              <div className="frame-63">
                <div className="frame-64">
                  <div className="frame-65">
                    <div className="frame-66">
                      <Icons54 className="icons-7" />
                      <PropertyNuts className="icons-8" image="/img/image-1-1-2x.png" />
                    </div>
                    <p className="div-5">
                      <span className="text-wrapper-42">Stake $NUTS for </span>
                      <span className="text-wrapper-43">Free $GOO Airdrop</span>
                    </p>
                  </div>
                  <div className="text-wrapper-44">50%</div>
                </div>
                <PropertyWrapper className="view" property1="fifty" />
              </div>
              <div className="frame-67">
                <div className="frame-68">
                  <div className="frame-68">
                    <div className="frame-69">
                      <PropertyTarget />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Hard Cap</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">Unlimited</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-69">
                      <Icons53 className="icons-7" />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Amount of GOO</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">250,000 GOO</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-69">
                      <PropertyMoney />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Price</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">Free</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Wallet
                  className="wallet-2"
                  divClassName="wallet-3"
                  icon={<Property1StarBoldDoutone className="property-1-star-bold-doutone" />}
                  property1="wallet-connect"
                  text="100.123 NUTS Staked"
                />
              </div>
            </div>
            <div className="tiers-2">
              <div className="frame-63">
                <div className="frame-72">
                  <div className="frame-73">
                    <div className="frame-66">
                      <Icons54 className="icons-7" />
                      <PropertySol className="icons-8" />
                    </div>
                    <p className="div-5">
                      <span className="text-wrapper-42">Stake $SOL for </span>
                      <span className="text-wrapper-43">Whitelist Subscription </span>
                    </p>
                  </div>
                  <div className="text-wrapper-47">50%</div>
                </div>
                <PropertyWrapper className="view" property1="fifty" />
              </div>
              <div className="frame-67">
                <div className="frame-68">
                  <div className="frame-68">
                    <div className="frame-69">
                      <PropertyTarget />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Hard Cap</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">6,500 SOL</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-69">
                      <Icons53 className="icons-7" />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Amount of GOO</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">3,500,000 GOO</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-69">
                      <PropertyMoney />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Price</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">0.0185 SOL</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Wallet
                  className="wallet-2"
                  divClassName="wallet-3"
                  icon={<Property1StarBoldDoutone className="property-1-star-bold-doutone" />}
                  property1="wallet-connect"
                  text="2 SOL Staked"
                />
              </div>
            </div>
            <div className="tiers-2">
              <div className="frame-63">
                <div className="frame-72">
                  <div className="frame-73">
                    <div className="frame-66">
                      <Icons54 className="icons-7" />
                      <PropertySol className="icons-8" />
                    </div>
                    <p className="stake-NUTS-for-free-2">Stake $SOL for Public Subscription</p>
                  </div>
                  <div className="text-wrapper-47">0%</div>
                </div>
                <PropertyWrapper className="view" property1="one" />
              </div>
              <div className="frame-67">
                <div className="frame-68">
                  <div className="frame-68">
                    <div className="frame-69">
                      <PropertyTarget />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Hard Cap</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">3,500 SOL</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-69">
                      <Icons53 className="icons-7" />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Amount of GOO</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">1,250,000 GOO</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-69">
                      <PropertyMoney />
                      <div className="frame-70">
                        <div className="text-wrapper-45">Price</div>
                        <div className="frame-71">
                          <div className="text-wrapper-46">0.0028 SOL</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Wallet
                  className="wallet-4"
                  divClassName="wallet-5"
                  property1="wallet-connect"
                  text="Stake Now"
                  visible={false}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer className="footer-instance" elementGooberAllClassName="footer-2" />
        <div className="nav">
          <div className="group-wrapper">
            <div className="group-4">
              <div className="logo">
                <div className="group-5">
                  <div className="frame-74">
                    <div className="group-6">
                      <div className="ellipse-wrapper">
                        <div className="ellipse" />
                      </div>
                      <div className="group-7">
                        <div className="ellipse" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-8">
                  <img className="group-9" alt="Group" src="/img/group-2.png" />
                  <div className="group-10">
                    <div className="ellipse-2" />
                  </div>
                  <div className="group-11">
                    <div className="ellipse-3" />
                  </div>
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <div className="ellipse-4" />
                      <div className="rectangle-3" />
                    </div>
                  </div>
                  <img className="subtract" alt="Subtract" src="/img/subtract-4.svg" />
                  <div className="frame-75">
                    <div className="rectangle-4" />
                    <div className="ellipse-5" />
                  </div>
                </div>
              </div>
              <div className="frame-76">
                <div className="text-wrapper-48">Stake</div>
                <div className="text-wrapper-49">About</div>
                <div className="text-wrapper-50">Roadmap</div>
                <div className="text-wrapper-51">Tokenomic</div>
              </div>
              <div className="frame-77">
                <div className="frame-78">
                  <div className="twitter-4">
                    <X6 className="x-12" color="white" />
                  </div>
                  <img className="discord-3" alt="Discord" src="/img/discord.svg" />
                </div>
                <Wallet className="wallet-6" property1="phantom-wallet-connected" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
