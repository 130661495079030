import React from "react";
import { Link } from "react-router-dom";
import { PropertyGoo } from "../../components/PropertyGoo";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyTwitter } from "../../components/PropertyTwitter";
import { PropertyTwoCoins } from "../../components/PropertyTwoCoins";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { Icons30 } from "../../icons/Icons30";
import { Icons53 } from "../../icons/Icons53";
import { Icons54 } from "../../icons/Icons54";
import { Property1Variant7 } from "../../icons/Property1Variant7";
import { X6 } from "../../icons/X6";
import "./style.css";

export const SuccessShareOnTw = () => {
  return (
    <div className="success-share-on-tw">
      <div className="div-6">
        <div className="overlap">
          <div className="frame-113">
            <div className="frame-114">
              <div className="frame-115">
                <div className="group-32">
                  <div className="frame-116">
                    <div className="text-wrapper-73">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-117">
                  <p className="stake-to-mint-GOO-3">
                    <span className="text-wrapper-74">Stake to mint </span>
                    <span className="text-wrapper-75">$GOO</span>
                  </p>
                  <p className="text-wrapper-76">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-118">
                <div className="frame-119">
                  <div className="text-wrapper-77">Total GOO for Mint</div>
                  <div className="frame-120">
                    <div className="text-wrapper-78">10,000,000 GOO</div>
                  </div>
                </div>
                <div className="frame-121">
                  <div className="text-wrapper-77">Staking Period</div>
                  <div className="frame-120">
                    <div className="text-wrapper-78">3 Days</div>
                  </div>
                </div>
                <div className="frame-122">
                  <div className="text-wrapper-77">Total NUTS Staked</div>
                  <div className="frame-120">
                    <div className="text-wrapper-78">10,000 NUTS</div>
                  </div>
                </div>
                <div className="frame-123">
                  <div className="text-wrapper-77">Total SOL Staked</div>
                  <div className="frame-120">
                    <div className="text-wrapper-78">1,000 SOL</div>
                  </div>
                </div>
              </div>
              <div className="user-profile-2">
                <div className="nuts-2">
                  <PropertyNuts className="icons-9" image="/img/image-1-1.png" imageClassName="icons-10" />
                  <div className="element-3">
                    <div className="text-wrapper-77">Your Total Staked NUTS</div>
                    <div className="frame-120">
                      <div className="text-wrapper-78">100 NUTS</div>
                    </div>
                  </div>
                </div>
                <div className="sol-3">
                  <PropertySol className="icons-9" imageClassName="property-1-SOL-instance" />
                  <div className="element-4">
                    <div className="text-wrapper-77">Your Total Staked SOL</div>
                    <div className="frame-120">
                      <div className="text-wrapper-78">2 SOL</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-124">
              <Tabs
                className="tabs-6"
                iconify29Color="#D4D4D4"
                iconify77Color="#FFBA15"
                img="/img/vector-10-1.svg"
                property1="tabs-tiers"
                vector="/img/vector-10.svg"
              />
              <div className="tiers-3">
                <div className="frame-125">
                  <div className="frame-126">
                    <div className="frame-127">
                      <div className="frame-128">
                        <Icons54 className="icons-11" />
                        <PropertyNuts className="icons-12" image="/img/image-1-1-2x.png" />
                      </div>
                      <p className="div-7">
                        <span className="text-wrapper-79">Stake $NUTS for </span>
                        <span className="text-wrapper-80">Free $GOO Airdrop</span>
                      </p>
                    </div>
                    <div className="text-wrapper-81">0%</div>
                  </div>
                  <PropertyWrapper className="component-5508-instance" property1="one" />
                </div>
                <div className="frame-129">
                  <div className="frame-130">
                    <div className="frame-130">
                      <div className="frame-131">
                        <PropertyTarget />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Hard Cap</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">Unlimited</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-131">
                        <Icons53 className="icons-11" />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Amount of GOO</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-131">
                        <PropertyMoney />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Price</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">Free</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-11"
                    divClassName="wallet-12"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-3">
                <div className="frame-125">
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="frame-128">
                        <Icons30 className="icons-30" />
                        <PropertySol className="icons-12" />
                      </div>
                      <p className="div-7">
                        <span className="text-wrapper-79">Stake $SOL for </span>
                        <span className="text-wrapper-80">Whitelist Subscription </span>
                      </p>
                    </div>
                    <div className="text-wrapper-84">0%</div>
                  </div>
                  <PropertyWrapper className="component-5508-instance" property1="one" />
                </div>
                <div className="frame-129">
                  <div className="frame-130">
                    <div className="frame-130">
                      <div className="frame-131">
                        <PropertyTarget />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Hard Cap</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">6,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-131">
                        <PropertyTwoCoins />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Amount of GOO</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">3,500,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-131">
                        <PropertyMoney />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Price</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">0.0185 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-11"
                    divClassName="wallet-12"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-3">
                <div className="frame-125">
                  <div className="frame-134">
                    <div className="frame-135">
                      <div className="frame-128">
                        <PropertyGoo />
                        <PropertySol className="icons-12" />
                      </div>
                      <p className="stake-NUTS-for-free-3">Stake $SOL for Public Subscription</p>
                    </div>
                    <div className="text-wrapper-84">0%</div>
                  </div>
                  <PropertyWrapper className="component-5508-instance" property1="one" />
                </div>
                <div className="frame-129">
                  <div className="frame-130">
                    <div className="frame-130">
                      <div className="frame-131">
                        <PropertyTarget />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Hard Cap</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">3,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-131">
                        <PropertyTwoCoins />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Amount of GOO</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">1,250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-131">
                        <PropertyMoney />
                        <div className="frame-132">
                          <div className="text-wrapper-82">Price</div>
                          <div className="frame-133">
                            <div className="text-wrapper-83">0.0028 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-11"
                    divClassName="wallet-12"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="nav-wrapper">
            <div className="nav-4">
              <div className="group-33">
                <div className="logo-4">
                  <div className="group-34">
                    <div className="frame-136">
                      <div className="group-35">
                        <div className="group-36">
                          <div className="ellipse-16" />
                        </div>
                        <div className="group-37">
                          <div className="ellipse-16" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-38">
                    <img className="group-39" alt="Group" src="/img/group-2.png" />
                    <div className="group-40">
                      <div className="ellipse-17" />
                    </div>
                    <div className="group-41">
                      <div className="ellipse-18" />
                    </div>
                    <div className="group-42">
                      <div className="overlap-group-6">
                        <div className="ellipse-19" />
                        <div className="rectangle-9" />
                      </div>
                    </div>
                    <img className="subtract-4" alt="Subtract" src="/img/subtract-4.svg" />
                    <div className="frame-137">
                      <div className="rectangle-10" />
                      <div className="ellipse-20" />
                    </div>
                  </div>
                </div>
                <div className="frame-138">
                  <div className="text-wrapper-85">Stake</div>
                  <div className="text-wrapper-86">About</div>
                  <div className="text-wrapper-87">Roadmap</div>
                  <div className="text-wrapper-88">Tokenomic</div>
                </div>
                <div className="frame-139">
                  <div className="frame-140">
                    <div className="twitter-5">
                      <X6 className="x-instance" color="white" />
                    </div>
                    <img className="discord-6" alt="Discord" src="/img/discord.svg" />
                  </div>
                  <Wallet className="wallet-13" property1="phantom-wallet-connected" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-19.svg" />
        <Link className="wallet-connect-2" to="/staked">
          <div className="frame-141">
            <div className="frame-142">
              <div className="frame-143">
                <div className="frame-144">
                  <div className="text-wrapper-89">🎉Success!</div>
                  <Property1Variant7 className="property-1-variant7" color="#202020" />
                </div>
              </div>
              <p className="you-ve-staked">
                <span className="text-wrapper-90">You&#39;ve staked </span>
                <span className="text-wrapper-91">1.2345 SOL</span>
                <span className="text-wrapper-90">
                  {" "}
                  for GOO. Share this on Twitter with your friends to qualify for a
                </span>
                <span className="text-wrapper-91"> supporter airdrop!</span>
                <span className="text-wrapper-90"> 🎁</span>
              </p>
            </div>
          </div>
          <div className="frame-145">
            <div className="frame-146">
              <div className="frame-147">
                <div className="frame-148">
                  <div className="frame-149">
                    <div className="GOO-staking-is-now-wrapper">
                      <input className="GOO-staking-is-now" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-150">
            <div className="frame-151">
              <div className="frame-147">
                <div className="frame-148">
                  <div className="frame-152">
                    <div className="frame-153">
                      <div className="frame-154">
                        <PropertyTwitter
                          className="property-1-twitter"
                          twitterTwitterClassName="property-twitter-instance"
                          twitterX6StyleOverrideClassName="property-1-twitter-instance"
                        />
                        <div className="text-wrapper-92">Share it on Twitter</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
