/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PropertyNuts } from "../PropertyNuts";
import "./style.css";

export const Icons = ({ property1, className }) => {
  return (
    <div className={`icons ${property1} ${className}`}>
      {property1 === "nut" && (
        <PropertyNuts
          className="property-NUTS-instance"
          image="/img/image-1-2.png"
          imageClassName="property-1-NUTS-instance"
        />
      )}

      {property1 === "sol" && <img className="image-2" alt="Image" src="/img/image-2.png" />}
    </div>
  );
};

Icons.propTypes = {
  property1: PropTypes.oneOf(["sol", "nut"]),
};
