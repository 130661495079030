/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Iconify63 } from "../../icons/Iconify63";
import "./style.css";

export const PropertyMoney = ({ className, iconify63StyleOverrideClassName }) => {
  return (
    <div className={`property-money ${className}`}>
      <Iconify63 className={iconify63StyleOverrideClassName} />
    </div>
  );
};
