/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { X6 } from "../../icons/X6";
import "./style.css";

export const Twitter = ({ className, x6StyleOverrideClassName }) => {
  return (
    <div className={`twitter ${className}`}>
      <X6 className={x6StyleOverrideClassName} color="white" />
    </div>
  );
};
