/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Iconify11 } from "../../icons/Iconify11";
import "./style.css";

export const PropertyTarget = ({ className, icon = <Iconify11 className="iconify-11" /> }) => {
  return <div className={`property-target ${className}`}>{icon}</div>;
};
