/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Iconify26 } from "../../icons/Iconify26";
import "./style.css";

export const WalletWrapper = ({ divClassName, className, text = "Connect Wallet to Stake" }) => {
  return (
    <div className={`wallet-wrapper ${className}`}>
      <div className="frame-26">
        <Iconify26 className="iconify-8" color="#FFBA15" />
        <div className={`connect-wallet-to ${divClassName}`}>{text}</div>
      </div>
    </div>
  );
};

WalletWrapper.propTypes = {
  text: PropTypes.string,
};
