// CountdownTimer.js
import React, { useState, useEffect } from 'react';
import './styles.css';  // Importing styles if you have any

function CountdownTimer({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      const difference = +new Date(targetDate) - +new Date();
      let timeLeft = calculateTimeLeft(difference);

      if (difference < 0) {
        clearInterval(timer);
        timeLeft = calculateTimeLeft(0);
      }

      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  function calculateTimeLeft(difference) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return (
        <p className="element-d-hr-min-s">
          <span className="text-wrapper-231">{timeLeft.days}</span>
          <span className="text-wrapper-232">D</span>
          <span className="text-wrapper-231"> {timeLeft.hours}</span>
          <span className="text-wrapper-232">Hr</span>
          <span className="text-wrapper-231"> {timeLeft.minutes}</span>
          <span className="text-wrapper-232">Min</span>
          <span className="text-wrapper-231"> {timeLeft.seconds}</span>
          <span className="text-wrapper-232">S</span>
        </p>
  );
}

export default CountdownTimer;
