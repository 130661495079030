import React from "react";
import { Link } from "react-router-dom";
import { Iconify } from "../../components/Iconify";
import { PropertyGoo } from "../../components/PropertyGoo";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyTwoCoins } from "../../components/PropertyTwoCoins";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Tabs } from "../../components/Tabs";
import { Wallet } from "../../components/Wallet";
import { X } from "../../components/X";
import "./style.css";

export const StakeInNuts = () => {
  return (
    <div className="stake-in-NUTS">
      <div className="div-14">
        <div className="overlap-8">
          <div className="frame-369">
            <div className="frame-370">
              <div className="frame-371">
                <div className="group-122">
                  <div className="frame-372">
                    <div className="text-wrapper-201">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-373">
                  <p className="stake-to-mint-GOO-11">
                    <span className="text-wrapper-202">Stake to mint </span>
                    <span className="text-wrapper-203">$GOO</span>
                  </p>
                  <p className="text-wrapper-204">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-374">
                <div className="frame-375">
                  <div className="text-wrapper-205">Total GOO for Mint</div>
                  <div className="frame-376">
                    <div className="text-wrapper-206">10,000,000 GOO</div>
                  </div>
                </div>
                <div className="frame-377">
                  <div className="text-wrapper-205">Staking Period</div>
                  <div className="frame-376">
                    <div className="text-wrapper-206">3 Days</div>
                  </div>
                </div>
                <div className="frame-378">
                  <div className="text-wrapper-205">Total NUTS Staked</div>
                  <div className="frame-376">
                    <div className="text-wrapper-206">10,000 NUTS</div>
                  </div>
                </div>
                <div className="frame-379">
                  <div className="text-wrapper-205">Total SOL Staked</div>
                  <div className="frame-376">
                    <div className="text-wrapper-206">1,000 SOL</div>
                  </div>
                </div>
              </div>
              <div className="user-profile-6">
                <div className="nuts-6">
                  <PropertyNuts className="icons-39" imageClassName="icons-41" />
                  <div className="element-11">
                    <div className="text-wrapper-205">Your Total Staked NUTS</div>
                    <div className="frame-376">
                      <div className="text-wrapper-206">100 NUTS</div>
                    </div>
                  </div>
                </div>
                <div className="sol-7">
                  <PropertySol className="icons-39" imageClassName="icons-42" />
                  <div className="element-12">
                    <div className="text-wrapper-205">Your Total Staked SOL</div>
                    <div className="frame-376">
                      <div className="text-wrapper-206">2 SOL</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-380">
              <Tabs className="tabs-12" property1="tabs-tiers" />
              <div className="tiers-10">
                <div className="frame-381">
                  <div className="frame-382">
                    <div className="frame-383">
                      <div className="frame-384">
                        <PropertyGoo />
                        <PropertyNuts className="icons-45" />
                      </div>
                      <p className="div-15">
                        <span className="text-wrapper-207">Stake $NUTS for </span>
                        <span className="text-wrapper-208">Free $GOO Airdrop</span>
                      </p>
                    </div>
                    <div className="text-wrapper-209">0%</div>
                  </div>
                  <PropertyWrapper className="view-6" property1="one" />
                </div>
                <div className="frame-385">
                  <div className="frame-386">
                    <div className="frame-386">
                      <div className="frame-387">
                        <PropertyTarget />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Hard Cap</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">Unlimited</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-387">
                        <PropertyTwoCoins />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Amount of GOO</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-387">
                        <PropertyMoney />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Price</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">Free</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-27"
                    divClassName="wallet-28"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-10">
                <div className="frame-381">
                  <div className="frame-390">
                    <div className="frame-391">
                      <div className="frame-384">
                        <PropertyGoo />
                        <PropertySol className="icons-45" />
                      </div>
                      <p className="div-15">
                        <span className="text-wrapper-207">Stake $SOL for </span>
                        <span className="text-wrapper-208">Whitelist Subscription </span>
                      </p>
                    </div>
                    <div className="text-wrapper-212">0%</div>
                  </div>
                  <PropertyWrapper className="view-6" property1="one" />
                </div>
                <div className="frame-385">
                  <div className="frame-386">
                    <div className="frame-386">
                      <div className="frame-387">
                        <PropertyTarget />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Hard Cap</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">6,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-387">
                        <PropertyTwoCoins />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Amount of GOO</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">3,500,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-387">
                        <PropertyMoney />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Price</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">0.0185 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-27"
                    divClassName="wallet-28"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
              <div className="tiers-10">
                <div className="frame-381">
                  <div className="frame-390">
                    <div className="frame-391">
                      <div className="frame-384">
                        <PropertyGoo />
                        <PropertySol className="icons-45" />
                      </div>
                      <p className="stake-NUTS-for-free-10">Stake $SOL for Public Subscription</p>
                    </div>
                    <div className="text-wrapper-212">0%</div>
                  </div>
                  <PropertyWrapper className="view-6" property1="one" />
                </div>
                <div className="frame-385">
                  <div className="frame-386">
                    <div className="frame-386">
                      <div className="frame-387">
                        <PropertyTarget />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Hard Cap</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">3,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-387">
                        <PropertyTwoCoins />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Amount of GOO</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">1,250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-387">
                        <PropertyMoney />
                        <div className="frame-388">
                          <div className="text-wrapper-210">Price</div>
                          <div className="frame-389">
                            <div className="text-wrapper-211">0.0028 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-27"
                    divClassName="wallet-28"
                    property1="wallet-connect"
                    text="Stake Now"
                    visible={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="nav-15">
            <div className="nav-16">
              <div className="group-123">
                <div className="logo-12">
                  <div className="group-124">
                    <div className="frame-392">
                      <div className="group-125">
                        <div className="group-126">
                          <div className="ellipse-56" />
                        </div>
                        <div className="group-127">
                          <div className="ellipse-56" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-128">
                    <img className="group-129" alt="Group" />
                    <div className="group-130">
                      <div className="ellipse-57" />
                    </div>
                    <div className="group-131">
                      <div className="ellipse-58" />
                    </div>
                    <div className="group-132">
                      <div className="overlap-group-14">
                        <div className="ellipse-59" />
                        <div className="rectangle-31" />
                      </div>
                    </div>
                    <img className="subtract-12" alt="Subtract" />
                    <div className="frame-393">
                      <div className="rectangle-32" />
                      <div className="ellipse-60" />
                    </div>
                  </div>
                </div>
                <div className="frame-394">
                  <div className="text-wrapper-213">Stake</div>
                  <div className="text-wrapper-214">About</div>
                  <div className="text-wrapper-215">Roadmap</div>
                  <div className="text-wrapper-216">Tokenomic</div>
                </div>
                <div className="frame-395">
                  <div className="frame-396">
                    <div className="twitter-10">
                      <X state="default" />
                    </div>
                    <img className="discord-12" alt="Discord" />
                  </div>
                  <Wallet
                    className="wallet-30"
                    override={<Iconify property1="arrow-up-2" />}
                    phantomPhantomClassName="wallet-29"
                    property1="phantom-wallet-connected"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="rectangle-33" alt="Rectangle" />
        <Link className="wallet-connect-5" to="/stake-in-sol">
          <div className="frame-397">
            <div className="frame-398">
              <div className="frame-399">
                <div className="frame-400">
                  <div className="text-wrapper-217">Stake NUTS</div>
                  <Iconify property1="variant-7" />
                </div>
              </div>
              <p className="please-note-you-can-2">
                <span className="text-wrapper-218">Please note, you can </span>
                <span className="text-wrapper-219">only stake once</span>
                <span className="text-wrapper-218"> for </span>
                <span className="text-wrapper-219">each tier</span>
              </p>
            </div>
          </div>
          <div className="frame-401">
            <div className="frame-402">
              <div className="frame-403">
                <div className="frame-404">
                  <div className="frame-405">
                    <div className="frame-406">
                      <div className="frame-407">
                        <div className="text-wrapper-220">My NUTS:</div>
                        <div className="text-wrapper-221">2000</div>
                      </div>
                      <div className="frame-408">
                        <div className="text-wrapper-220">Minimum:</div>
                        <div className="text-wrapper-222">100 NUTS</div>
                      </div>
                    </div>
                    <div className="frame-409">
                      <PropertyNuts className="icons-46" imageClassName="icons-47" />
                      <div className="frame-410">
                        <input className="enter-NUTS-amounts" />
                        <div className="text-wrapper-223">MAX</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-411">
            <div className="frame-412">
              <div className="frame-403">
                <div className="frame-404">
                  <div className="frame-413">
                    <div className="frame-414">
                      <div className="text-wrapper-224">Stake NUTS</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
