/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { X15 } from "../../icons/X15";
import "./style.css";

export const FooterWrapper = ({ className }) => {
  return (
    <div className={`footer-wrapper ${className}`}>
      <div className="frame-40">
        <div className="frame-41">
          <div className="frame-42">
            <div className="text-wrapper-25">Explore More</div>
          </div>
          <div className="frame-43">
            <div className="text-wrapper-26">Stake</div>
            <div className="text-wrapper-27">About</div>
            <div className="text-wrapper-27">Roadmap</div>
            <div className="text-wrapper-27">Tokenomic</div>
          </div>
        </div>
        <div className="frame-44">
          <div className="frame-45">
            <div className="text-wrapper-28">Community</div>
            <div className="group-2">
              <div className="twitter-3">
                <X15 className="x-15" />
              </div>
              <img className="discord-2" alt="Discord" src="/img/discord-12.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="frame-46">
        <p className="element-goober-all-2">
          <span className="text-wrapper-29">©</span>
          <span className="text-wrapper-30">2024 Goober All rights reserved | </span>
          <span className="text-wrapper-31">Terms &amp; Conditons</span>
        </p>
      </div>
    </div>
  );
};
