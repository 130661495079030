/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons21 = ({ className }) => {
  return (
    <svg
      className={`icons-21 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_273_4857)">
        <circle className="circle" cx="12" cy="12.5" fill="white" r="11.76" stroke="#FAFAFA" strokeWidth="0.48" />
        <g className="g" clipPath="url(#clip1_273_4857)">
          <path
            className="path"
            d="M12.2229 8.22364C11.2773 8.22205 10.0926 8.52087 8.92387 9.12915C7.75698 9.73744 6.83207 10.5343 6.28913 11.3126C5.74593 12.0882 5.59904 12.8107 5.86626 13.326C6.13374 13.8387 6.81002 14.1335 7.75804 14.1335C8.70605 14.1362 9.89074 13.836 11.0568 13.2304C12.2256 12.6221 13.15 11.8226 13.6918 11.047C14.2364 10.2713 14.3798 9.54884 14.1142 9.03353C13.8459 8.52087 13.1712 8.22364 12.2229 8.22364ZM14.664 10.0907C14.5711 10.4971 14.3692 10.9142 14.085 11.3206C13.4846 12.1785 12.5045 13.0152 11.2773 13.6527C10.0501 14.2929 8.80433 14.6143 7.75777 14.6143C7.26371 14.6143 6.80577 14.5399 6.41982 14.3832L6.70112 14.9251C6.96887 15.4404 7.64329 15.7352 8.59183 15.7352C9.54012 15.7352 10.7248 15.4377 11.8909 14.8295C13.0596 14.2238 13.984 13.4243 14.5259 12.646C15.0678 11.8704 15.2165 11.1479 14.9482 10.6352L14.664 10.0907ZM15.429 10.537C15.7265 11.2462 15.4875 12.1095 14.919 12.9196C14.417 13.6395 13.6493 14.3381 12.6984 14.9224C12.9932 14.9543 13.3014 14.9702 13.6148 14.9702C14.9296 14.9702 16.1196 14.6887 16.959 14.2504C17.8011 13.8121 18.2632 13.2384 18.2632 12.6593C18.2632 12.0802 17.8011 11.5065 16.959 11.0682C16.5314 10.8451 16.0107 10.6618 15.429 10.537ZM18.2632 13.852C17.9923 14.1681 17.6204 14.447 17.1821 14.6754C16.2525 15.1588 14.9987 15.4484 13.6148 15.4484C13.0517 15.4484 12.5098 15.4006 12.0025 15.3102C11.3862 15.621 10.7673 15.8521 10.1723 16.0035C10.2042 16.0221 10.2361 16.0381 10.2706 16.0567C11.11 16.4949 12.3 16.7765 13.6148 16.7765C14.9296 16.7765 16.1196 16.4949 16.959 16.0567C17.8011 15.6184 18.2632 15.0446 18.2632 14.4656V13.852Z"
            fill="#FFBA15"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_273_4857">
          <rect className="rect" fill="white" height="24" transform="translate(0 0.5)" width="24" />
        </clipPath>
        <clipPath className="clip-path" id="clip1_273_4857">
          <rect className="rect" fill="white" height="13.6" transform="translate(5.19995 5.7002)" width="13.6" />
        </clipPath>
      </defs>
    </svg>
  );
};
