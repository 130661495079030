import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Iconify26 } from "../../icons/Iconify26";
import { WalletModalButton } from '@solana/wallet-adapter-react-ui'; // Make sure to import this
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'


import "./style.css"; // Ensure this file is correctly linked

export const ConnectButton = () => {
    const { connect, disconnect, connected } = useWallet();

    const handleConnect = async () => {
        try {
            await connect();
        } catch (error) {
            console.error("Failed to connect:", error);
        }
    };


    // return connected ?(
    //     <button
    //         onClick={handleConnect}
    //         className={`wallet-button ${connected ? 'connected' : ''}`}
    //     >
    //         <Iconify26 className="iconify-26" color='white'/>
    //         {connected ? 'Connected' : <div className={`text-wrapper-5`}>Connect Wallet</div>}

    //     </button>
    // ) :(<WalletModalButton className="wallet-button">
    //         Connect Wallet
    //     </WalletModalButton>);
    return (
        <WalletMultiButton className="wallet-button"/>
    )
};
