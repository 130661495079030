/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Iconify63 = ({ className }) => {
  return (
    <svg
      className={`iconify-63 ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 34 34"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.0083 31.1722C9.18409 31.1722 2.84167 24.8298 2.84167 17.0055C2.84167 9.18128 9.18409 2.83887 17.0083 2.83887C24.8326 2.83887 31.175 9.18128 31.175 17.0055C31.175 24.8298 24.8326 31.1722 17.0083 31.1722ZM12.05 19.8389V22.6722H15.5917V25.5055H18.425V22.6722H19.8417C20.781 22.6722 21.6818 22.2991 22.346 21.6349C23.0102 20.9707 23.3833 20.0698 23.3833 19.1305C23.3833 18.1912 23.0102 17.2904 22.346 16.6262C21.6818 15.962 20.781 15.5889 19.8417 15.5889H14.175C13.9871 15.5889 13.807 15.5142 13.6741 15.3814C13.5413 15.2486 13.4667 15.0684 13.4667 14.8805C13.4667 14.6927 13.5413 14.5125 13.6741 14.3797C13.807 14.2468 13.9871 14.1722 14.175 14.1722H21.9667V11.3389H18.425V8.50553H15.5917V11.3389H14.175C13.2357 11.3389 12.3349 11.712 11.6707 12.3762C11.0065 13.0404 10.6333 13.9412 10.6333 14.8805C10.6333 15.8198 11.0065 16.7207 11.6707 17.3849C12.3349 18.0491 13.2357 18.4222 14.175 18.4222H19.8417C20.0295 18.4222 20.2097 18.4968 20.3425 18.6297C20.4754 18.7625 20.55 18.9427 20.55 19.1305C20.55 19.3184 20.4754 19.4986 20.3425 19.6314C20.2097 19.7642 20.0295 19.8389 19.8417 19.8389H12.05Z"
        fill="#FFBA15"
      />
    </svg>
  );
};
