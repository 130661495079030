/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Property1TwoCoins = ({ className }) => {
  return (
    <svg
      className={`property-1-two-coins ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 34 34"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.5583 6.30959C15.1942 6.3056 12.2325 7.05267 9.31064 8.57338C6.39341 10.0941 4.08115 12.0863 2.72381 14.032C1.3658 15.971 0.998571 17.7773 1.66662 19.0656C2.33533 20.3472 4.02603 21.0843 6.39607 21.0843C8.76611 21.091 11.7278 20.3406 14.6431 18.8265C17.5649 17.3058 19.8759 15.307 21.2306 13.3679C22.5919 11.4288 22.9505 9.62259 22.2864 8.33431C21.6157 7.05267 19.929 6.30959 17.5583 6.30959ZM23.661 10.9773C23.4286 11.9933 22.9239 13.0359 22.2134 14.0519C20.7126 16.1968 18.2622 18.2886 15.1942 19.8824C12.1263 21.4827 9.01181 22.2863 6.39541 22.2863C5.16025 22.2863 4.01541 22.1003 3.05052 21.7085L3.75377 23.0632C4.42314 24.3515 6.1092 25.0886 8.48056 25.0886C10.8513 25.0886 13.813 24.3449 16.7282 22.8242C19.6501 21.3101 21.961 19.3113 23.3157 17.3656C24.6704 15.4265 25.0423 13.6203 24.3716 12.3386L23.661 10.9773ZM25.5735 12.0929C26.3173 13.866 25.7196 16.0242 24.2985 18.0495C23.0435 19.8492 21.1243 21.5956 18.747 23.0566C19.4841 23.1363 20.2544 23.1761 21.038 23.1761C24.3251 23.1761 27.3001 22.4722 29.3985 21.3765C31.5036 20.2808 32.6591 18.8464 32.6591 17.3988C32.6591 15.9511 31.5036 14.5167 29.3985 13.421C28.3294 12.8632 27.0278 12.405 25.5735 12.0929ZM32.6591 20.3804C31.9817 21.1706 31.052 21.8679 29.9563 22.439C27.6321 23.6476 24.4977 24.3714 21.038 24.3714C19.6302 24.3714 18.2755 24.2519 17.0071 24.0261C15.4665 24.8031 13.9192 25.3808 12.4317 25.7593C12.5114 25.8058 12.5911 25.8456 12.6774 25.8921C14.7759 26.9878 17.7509 27.6917 21.038 27.6917C24.3251 27.6917 27.3001 26.9878 29.3985 25.8921C31.5036 24.7964 32.6591 23.362 32.6591 21.9144V20.3804Z"
        fill="#FFBA15"
      />
    </svg>
  );
};
