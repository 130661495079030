/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { StateHover } from "../../icons/StateHover";
import { X6 } from "../../icons/X6";
import "./style.css";

export const X = ({ state }) => {
  return (
    <>
      {state === "default" && <X6 className="state-default" color="#1C1C1C" />}

      {state === "hover" && <StateHover className="state-hover" />}
    </>
  );
};

X.propTypes = {
  state: PropTypes.oneOf(["hover", "default"]),
};
