/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Iconify62 } from "../../icons/Iconify62";
import { Icons21 } from "../../icons/Icons21";
import { Icons25 } from "../../icons/Icons25";
import { Icons } from "../Icons";
import { PropertyMoney } from "../PropertyMoney";
import { PropertyTarget } from "../PropertyTarget";
import { WalletWrapper } from "../WalletWrapper";
import "./style.css";

export const MobileTiers = ({
  className,
  propertyTargetIcon = <Iconify62 className="iconify-62" />,
  walletWrapperDivClassName,
  iconsPropertyNutClassName,
  iconsProperty1 = "nut",
  text = "Stake $NUTS for ",
  text1 = "Free $GOO Airdrop",
  text2 = "Unlimited",
  text3 = "250,000 GOO",
  text4 = "Free",
  walletWrapperText = "Connect Wallet to Stake",
  walletWrapperWalletClassName,
}) => {
  return (
    <div className={`mobile-tiers ${className}`}>
      <div className="frame-27">
        <div className="frame-28">
          <div className="frame-29">
            <div className="frame-30">
              <Icons25 className="icons-25" />
              <Icons className={iconsPropertyNutClassName} property1={iconsProperty1} />
            </div>
          </div>
          <p className="p">
            <span className="text-wrapper-20">{text}</span>
            <span className="text-wrapper-21">{text1}</span>
          </p>
        </div>
        <div className="frame-31">
          <div className="frame-32">
            <div className="text-wrapper-22">0%</div>
            <div className="rectangle-wrapper">
              <div className="rectangle-2" />
            </div>
          </div>
          <div className="frame-33">
            <div className="frame-34">
              <div className="frame-35">
                <PropertyTarget className="icons-2" icon={propertyTargetIcon} />
                <div className="frame-36">
                  <div className="text-wrapper-23">Hard Cap</div>
                  <div className="frame-37">
                    <div className="text-wrapper-24">{text2}</div>
                  </div>
                </div>
              </div>
              <div className="frame-38">
                <Icons21 className="icons-21" />
                <div className="frame-36">
                  <div className="text-wrapper-23">Amount of GOO</div>
                  <div className="frame-37">
                    <div className="text-wrapper-24">{text3}</div>
                  </div>
                </div>
              </div>
              <div className="frame-39">
                <PropertyMoney className="icons-2" iconify63StyleOverrideClassName="iconify-62" />
                <div className="frame-36">
                  <div className="text-wrapper-23">Price</div>
                  <div className="frame-37">
                    <div className="text-wrapper-24">{text4}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WalletWrapper
        className={walletWrapperWalletClassName}
        divClassName={walletWrapperDivClassName}
        text={walletWrapperText}
      />
    </div>
  );
};

MobileTiers.propTypes = {
  iconsProperty1: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  walletWrapperText: PropTypes.string,
};
