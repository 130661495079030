/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons25 = ({ className }) => {
  return (
    <svg
      className={`icons-25 ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_273_4464)">
        <circle className="circle" cx="15" cy="15" fill="white" r="14.7" stroke="#FAFAFA" strokeWidth="0.6" />
        <path
          className="path"
          d="M17.8103 22.5399C16.5315 22.5399 15.2512 22.0627 14.2394 21.1038C13.3773 20.2868 12.8169 19.1906 12.6614 18.0171C12.6155 17.6707 12.3449 17.4054 11.9882 17.357C10.8547 17.2033 9.82725 16.6935 9.01675 15.8829C7.00216 13.8679 6.9933 10.5809 8.99703 8.55542C11.0022 6.52861 14.1927 6.47608 16.2606 8.43606C17.1226 9.25298 17.683 10.3492 17.8386 11.5227C17.8845 11.8692 18.1551 12.1345 18.5118 12.1828C19.6453 12.3366 20.6728 12.8463 21.4832 13.6569C23.4978 15.672 23.5067 18.9591 21.503 20.9844C20.479 22.0196 19.1454 22.5399 17.8103 22.5399Z"
          fill="#F5BC54"
        />
        <ellipse className="ellipse-76" cx="14.1241" cy="14.3556" fill="white" rx="1.93954" ry="1.93955" />
        <ellipse className="ellipse-76" cx="14.2617" cy="14.4934" fill="black" rx="1.42014" ry="1.42014" />
        <ellipse className="ellipse-76" cx="14.2617" cy="14.4934" fill="black" rx="1.42014" ry="1.42014" />
        <ellipse className="ellipse-76" cx="18.7926" cy="10.6778" fill="white" rx="1.93954" ry="1.93955" />
        <ellipse className="ellipse-76" cx="18.9302" cy="10.8157" fill="black" rx="1.42014" ry="1.42014" />
        <ellipse className="ellipse-76" cx="18.9302" cy="10.8157" fill="black" rx="1.42014" ry="1.42014" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_273_4464">
          <rect className="rect" fill="white" height="30" width="30" />
        </clipPath>
      </defs>
    </svg>
  );
};
