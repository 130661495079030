/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Iconify26 = ({ color = "#FFBA15", className }) => {
  return (
    <svg
      className={`iconify-26 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.3333 3.33301C16.7754 3.33301 17.1993 3.5086 17.5118 3.82116C17.8244 4.13372 18 4.55765 18 4.99967V5.83301H13C11.8949 5.83301 10.8351 6.27199 10.0537 7.0534C9.27232 7.8348 8.83333 8.89461 8.83333 9.99967C8.83333 11.1047 9.27232 12.1646 10.0537 12.946C10.8351 13.7274 11.8949 14.1663 13 14.1663H18V14.9997C18 15.4417 17.8244 15.8656 17.5118 16.1782C17.1993 16.4907 16.7754 16.6663 16.3333 16.6663H4.66667C4.22464 16.6663 3.80072 16.4907 3.48816 16.1782C3.17559 15.8656 3 15.4417 3 14.9997V4.99967C3 4.55765 3.17559 4.13372 3.48816 3.82116C3.80072 3.5086 4.22464 3.33301 4.66667 3.33301H16.3333ZM17.1667 7.49967C17.6087 7.49967 18.0326 7.67527 18.3452 7.98783C18.6577 8.30039 18.8333 8.72431 18.8333 9.16634V10.833C18.8333 11.275 18.6577 11.699 18.3452 12.0115C18.0326 12.3241 17.6087 12.4997 17.1667 12.4997H13C12.337 12.4997 11.7011 12.2363 11.2322 11.7674C10.7634 11.2986 10.5 10.6627 10.5 9.99967C10.5 9.33663 10.7634 8.70075 11.2322 8.23191C11.7011 7.76307 12.337 7.49967 13 7.49967H17.1667ZM13 9.16634C12.779 9.16634 12.567 9.25414 12.4107 9.41042C12.2545 9.5667 12.1667 9.77866 12.1667 9.99967C12.1667 10.2207 12.2545 10.4327 12.4107 10.5889C12.567 10.7452 12.779 10.833 13 10.833C13.221 10.833 13.433 10.7452 13.5893 10.5889C13.7455 10.4327 13.8333 10.2207 13.8333 9.99967C13.8333 9.77866 13.7455 9.5667 13.5893 9.41042C13.433 9.25414 13.221 9.16634 13 9.16634Z"
        fill={color}
      />
    </svg>
  );
};

Iconify26.propTypes = {
  color: PropTypes.string,
};
