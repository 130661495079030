import React from "react";
import { Iconify } from "../../components/Iconify";
import { PropertyGoo } from "../../components/PropertyGoo";
import { PropertyMoney } from "../../components/PropertyMoney";
import { PropertyNuts } from "../../components/PropertyNuts";
import { PropertySol } from "../../components/PropertySol";
import { PropertyTarget } from "../../components/PropertyTarget";
import { PropertyTwoCoins } from "../../components/PropertyTwoCoins";
import { PropertyWrapper } from "../../components/PropertyWrapper";
import { Tabs } from "../../components/Tabs";
import { Tiers } from "../../components/Tiers";
import { Wallet } from "../../components/Wallet";
import { Iconify11 } from "../../icons/Iconify11";
import { Iconify26 } from "../../icons/Iconify26";
import { Icons30 } from "../../icons/Icons30";
import { Property1Variant7 } from "../../icons/Property1Variant7";
import { X6 } from "../../icons/X6";
import "./style.css";

export const ConnectWallet = () => {
  return (
    <div className="connect-wallet">
      <div className="div-9">
        <div className="overlap-3">
          <div className="frame-199">
            <div className="frame-200">
              <div className="frame-201">
                <div className="group-66">
                  <div className="frame-202">
                    <div className="text-wrapper-116">Contract Power by TinySPL</div>
                  </div>
                </div>
                <div className="frame-203">
                  <p className="stake-to-mint-GOO-6">
                    <span className="text-wrapper-117">Stake to mint </span>
                    <span className="text-wrapper-118">$GOO</span>
                  </p>
                  <p className="text-wrapper-119">Stake today for earlier supporter airdrop🎁</p>
                </div>
              </div>
              <div className="frame-204">
                <div className="frame-205">
                  <div className="text-wrapper-120">Total GOO for Mint</div>
                  <div className="frame-206">
                    <div className="text-wrapper-121">10,000,000 GOO</div>
                  </div>
                </div>
                <div className="frame-207">
                  <div className="text-wrapper-120">Staking Period</div>
                  <div className="frame-206">
                    <div className="text-wrapper-121">7 Days</div>
                  </div>
                </div>
                <div className="frame-208">
                  <div className="text-wrapper-120">Total NUTS Staked</div>
                  <div className="frame-206">
                    <div className="text-wrapper-121">10,000 NUTS</div>
                  </div>
                </div>
                <div className="frame-209">
                  <div className="text-wrapper-120">Total SOL Staked</div>
                  <div className="frame-206">
                    <div className="text-wrapper-121">1,000 SOL</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-210">
              <Tabs
                className="tabs-8"
                iconify29Color="#FFBA15"
                iconify77Color="#FFBA15"
                img="/img/vector-10-1.svg"
                property1="tabs-tiers"
                vector="/img/vector-10.svg"
              />
              <Tiers
                className="design-component-instance-node-2"
                override={<PropertyNuts className="icons-15" image="/img/image-1-1-2x.png" />}
                propertyTargetIcon={<Iconify11 className="iconify-15" />}
                text="10,000,000 NUTS"
                text1="0.00186 SOL"
                text2="250,000 GOO"
                walletIcon={<Iconify26 className="iconify-4" color="#FFBA15" />}
              />
              <Tiers
                className="design-component-instance-node-2"
                icon={<Icons30 className="icons-36" />}
                icon1={<PropertyTwoCoins />}
                override={<PropertySol className="icons-15" />}
                spanClassName="tiers-5"
                spanClassNameOverride="tiers-5"
                stakeNutsForFreeClassName="tiers-4"
                text="6,500 SOL"
                text1="0.0185 SOL"
                text2="3,500,000 GOO"
                text3="Stake $SOL for "
                text4="Whitelist Mint"
                walletIcon={<Iconify property1="wallet-fill" />}
              />
              <div className="tiers-6">
                <div className="frame-211">
                  <div className="frame-212">
                    <div className="frame-213">
                      <div className="frame-214">
                        <PropertyGoo />
                        <PropertySol className="icons-15" />
                      </div>
                      <p className="stake-NUTS-for-free-5">Stake $SOL for Public Mint</p>
                    </div>
                    <div className="text-wrapper-122">0%</div>
                  </div>
                  <PropertyWrapper className="view-2" property1="one" />
                </div>
                <div className="frame-215">
                  <div className="frame-216">
                    <div className="frame-216">
                      <div className="frame-217">
                        <PropertyTarget />
                        <div className="frame-218">
                          <div className="text-wrapper-123">Hard Cap</div>
                          <div className="frame-219">
                            <div className="text-wrapper-124">3,500 SOL</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-217">
                        <PropertyTwoCoins />
                        <div className="frame-218">
                          <div className="text-wrapper-123">Amount of GOO</div>
                          <div className="frame-219">
                            <div className="text-wrapper-124">1,250,000 GOO</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-217">
                        <PropertyMoney />
                        <div className="frame-218">
                          <div className="text-wrapper-123">Price</div>
                          <div className="frame-219">
                            <div className="text-wrapper-124">0.035 SOL</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Wallet
                    className="wallet-14"
                    divClassName="wallet-15"
                    icon={<Iconify property1="wallet-fill" />}
                    property1="wallet-connect"
                    text="Connect Wallet to Stake"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-7">
          <div className="group-67">
            <div className="logo-7">
              <div className="group-68">
                <div className="frame-220">
                  <div className="group-69">
                    <div className="group-70">
                      <div className="ellipse-31" />
                    </div>
                    <div className="group-71">
                      <div className="ellipse-31" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-72">
                <img className="group-73" alt="Group" src="/img/group-2.png" />
                <div className="group-74">
                  <div className="ellipse-32" />
                </div>
                <div className="group-75">
                  <div className="ellipse-33" />
                </div>
                <div className="group-76">
                  <div className="overlap-group-9">
                    <div className="ellipse-34" />
                    <div className="rectangle-17" />
                  </div>
                </div>
                <img className="subtract-7" alt="Subtract" src="/img/subtract-15.svg" />
                <div className="frame-221">
                  <div className="rectangle-18" />
                  <div className="ellipse-35" />
                </div>
              </div>
            </div>
            <div className="navbar-3">
              <div className="text-wrapper-125">Stake</div>
              <div className="text-wrapper-126">About</div>
              <div className="text-wrapper-127">Roadmap</div>
              <div className="text-wrapper-128">Tokenomic</div>
            </div>
            <div className="frame-222">
              <div className="frame-223">
                <div className="twitter-6">
                  <X6 className="x-2" color="white" />
                </div>
                <img className="discord-8" alt="Discord" src="/img/discord-6.svg" />
              </div>
              <Wallet
                className="design-component-instance-node-2"
                icon={<Iconify26 className="iconify-4" color="white" />}
                property1="wallet-connect"
                text="Connect Wallet"
              />
            </div>
          </div>
        </div>
        <img className="rectangle-19" alt="Rectangle" src="/img/rectangle-19-1.svg" />
        <div className="wallet-connect-3">
          <div className="frame-224">
            <div className="frame-225">
              <div className="frame-226">
                <div className="frame-227">
                  <div className="text-wrapper-129">Connect Wallet</div>
                  <Property1Variant7 className="iconify-4" color="white" />
                </div>
              </div>
              <p className="text-wrapper-130">Please connect your wallet to begin</p>
            </div>
          </div>
          <div className="frame-228">
            <div className="frame-229">
              <div className="frame-230">
                <div className="frame-231">
                  <div className="frame-232">
                    <img className="frame-233" alt="Frame" src="/img/frame.svg" />
                    <div className="text-wrapper-131">Phantom</div>
                  </div>
                  <div className="text-wrapper-132">Detected</div>
                </div>
                <div className="frame-234">
                  <div className="frame-232">
                    <img className="image-3" alt="Image" src="/img/image-3.png" />
                    <div className="text-wrapper-131">Backpack</div>
                  </div>
                  <div className="text-wrapper-132">Detected</div>
                </div>
                <div className="frame-234">
                  <div className="frame-232">
                    <img className="frame-233" alt="Frame" src="/img/frame-1.svg" />
                    <div className="text-wrapper-131">Solflare</div>
                  </div>
                  <div className="text-wrapper-132">Detected</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
